﻿@import "../modules/constants";
@import "../modules/mixins";

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: $breakpoint-xxl;
    margin-top: 25px;
    @include respond-range(320px, 1024px) {
      width: 100%;
      padding: 0 16px;
    }

    .navbar-logo-section {
      background-image: url("../images/logo-FIN-TITLE-1-Dark-Blue@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 55px;
      width: 200px;
      @include respond-range(320px, 1024px) {
        width: 75px;
      }
    }
    .navbar-menu-section {
      display: flex;
      justify-content: space-between;
      width: 76%;
      max-width: 740px;
      @include respond-range(320px, 1024px) {
        display: none;
      }
      @include respond-range(768px, 1024px) {
        width: 90%;
      }

      .navbar-menu-services {
        color: #353b48;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
      .navbar-menu-separator {
        height: 12px;
        width: 2px;
        opacity: 0.7;
        background-color: #353b48;
        margin-top: 14px;
      }
      .navBar-TechHighlighter {
        height: 2px;
        background-color: #ffffff;
      }
      .navBar-servicesHighlighter {
        height: 2px;
        background-color: #cfcfcf;
      }
    }
    .navbar-hamburger {
      display: none;
      float: right;
      width: 22px;
      cursor: pointer;
      padding-bottom: 10px;

      @include respond-max(1024px) {
        display: block;
      }

      .hamburger-bar-homepage {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: #000000;
      }
    }
  }
  .navbar-mobile-menu-open {
    height: 100vh;
    width: 100vw;
    background: #f4f4f3;
    transition: 0.5s;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 900;
    left: 0;
    right: 0;

    .navbar-homeIcon {
      display: flex;
      justify-content: space-between;
      width: 95%;
      margin: auto;
      margin-top: 25px;
      @include respond-range(320px, 1024px) {
        width: 100%;
        padding: 0 16px;
      }
      .navBarIcon {
        height: 55px;
        width: 200px;

        .navbar-logo-section {
          background-image: url("../images/logo-FIN-TITLE-1-Dark-Blue@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          height: 55px;
          width: 200px;
          cursor: pointer;
          @include respond-range(320px, 1024px) {
            width: 75px;
          }
        }
      }
      .navbar-logo-section {
        background-image: url("../images/logo-FIN-TITLE-1-Dark-Blue@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        height: 55px;
        width: 200px;
        cursor: pointer;
        margin: 25px 0px 0px 16px;
        @include respond-range(320px, 1024px) {
          width: 75px;
          margin: 0;
        }
      }
    }

    .navbar-mobile-menu-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      max-height: 635px;

      .navbar-menuSeparator {
        height: 1px;
        width: 128px;
        border: 1px solid #d4d4d4;
      }
      .navbar-menu-item {
        color: #353b48 !important;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
      }

      .active {
        color: black !important;
      }

      .active.get-quote-link {
        color: black;
      }

      .navbar-menu-item:hover {
        color: black;
        cursor: pointer;
      }

      a:hover {
        color: black !important;
      }

      .menu-item-margin {
        margin-top: 6%;

        @include respond-min($breakpoint-sm) {
          margin-top: 46px;
        }
      }

      .contact-us-btn {
        background-color: black;
        width: 130px;
        height: 42px;
        text-align: center;
        letter-spacing: -0.25px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 13px;
        font-weight: bold;
      }

      .contact-us-btn:hover {
        background-color: black;
      }

      .btn-transparent:hover {
        color: #ffffff;
        background: black;
      }
    }

    .navbar-hamburger {
      display: block;
      float: right;
      width: 22px;
      cursor: pointer;
      margin-right: 4%;
      padding-bottom: 10px;

      @include respond-min($breakpoint-sm) {
        /*display: none;*/
      }

      .hamburger-bar-homepage,
      .hamburger-bar-tech-page,
      .hamburger-bar-about-page,
      .hamburger-privacy-page {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: blue;
      }

      .hamburger-bar-locations-page {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: #ffffff;
      }
    }
  }
}

.ui.modal > .header.border-none {
  border: none;
}

.close-mobile-menu-icon {
  color: black;
  font-size: 34px;
  line-height: 0.5;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 0 0;
}

.close-mobile-menu-icon-iframe-pad {
  padding: 0 15px;
}

.navbar-container-scrolling {
  position: fixed;
  background-color: #fff;
  z-index: 800;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  height: 100px;

  @include respond-range(320px, 1024px) {
    /*display: none!important;*/
  }

  .navbar-content-scrolling {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: $breakpoint-xxl;
    margin-top: 25px !important;
    margin: auto;

    @include respond-range(320px, 1024px) {
      /*display: none!important;*/
      width: 100%;
      padding: 0 16px;
    }

    .navbar-logo-section {
      background-image: url("../images/logo-FIN-TITLE-1-Dark-Blue@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 55px;
      width: 200px;
      cursor: pointer;

      @include respond-range(320px, 1024px) {
        width: 75px;
      }
    }

    .navbar-menu-section {
      display: flex;
      justify-content: space-between;
      width: 76%;
      max-width: 740px;

      @include respond-max(1024px) {
        display: none;
      }

      .navbar-menu-services {
        color: #353b48;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }

      .navbar-menu-services:hover {
        color: red !important;
      }

      .navbar-menu-separator {
        height: 12px;
        width: 2px;
        opacity: 0.7;
        background-color: #353b48;
        margin-top: 14px;
      }
    }

    .navbar-hamburger {
      display: none;
      float: right;
      width: 22px;
      cursor: pointer;
      padding-bottom: 10px;

      @include respond-max(1024px) {
        display: block;
      }

      .hamburger-bar-homepage {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: #000000;
      }
    }
  }
}

#qet-quote-mob-link {
  color: #4183c4 !important;
}

#qet-quote-mob-link:hover {
  color: black !important;
}

.navbar-container-scrolling.hide {
  display: none !important;
}

#scroll {
  @include respond-range(320px, 1024px) {
    /*display: none!important;*/
  }
}
.visible.transition {
  @include respond-range(320px, 1024px) {
    // display: none!important;
  }
}
