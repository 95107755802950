@import "../modules/constants";
@import "../modules/mixins";

.service-mainContainer {
  width: 100%;
  overflow: hidden;
  margin-bottom: 5%;

  .service-hero-container {
    background-image: url("../images/services-photo-hero@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 48vw;
    width: 100%;
    max-height: 600px;
    position: relative;
    background-position: bottom;

    @include respond-range(320px, 600px) {
      background-position: right;
      height: 250px;
    }

    @include respond-range(601px, 767px) {
      background-position: right;
      height: 350px;
    }

    @include respond-range(768px, 1024px) {
      height: 65vw;
      max-height: 481px;
      background-position: bottom;
    }

    .hero-contentWeb {
      height: 45vw;

      @include respond-range(320px, 767px) {
        display: none;
      }

      .hero-content-container {
        width: 100%;
        height: 100%;
        margin-top: 0;
        display: flex;
        justify-content: center;

        .hero-text-content {
          width: 100%;

          .hero-text-contentSec {
            display: flex;
            justify-content: center;
            width: 100%;
            position: absolute;
            z-index: 0;
            height: 105%;

            .hero-text-contentSecLeft {
              max-width: 1400px;
              width: 78%;
              margin-top: 7%;

              @include respond-range(320px, 1024px) {
                width: 90%;
              }

              .hero-title {
                color: #fdf7f5;
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 600;
                letter-spacing: -0.48px;
                line-height: 29px;

                @include respond-range(768px, 1024px) {
                  font-size: 18px;
                  line-height: 29px;
                }
              }

              .txt {
                line-height: 70px;
                margin-top: 1%;

                @include respond-range(768px, 1024px) {
                  font-size: 34px;
                  line-height: 43px;
                  margin-top: 0;
                }
              }

              .subTxt {
                font-weight: 300;
                line-height: 63px;

                @include respond-range(768px, 1024px) {
                  font-size: 34px;
                  line-height: 43px;
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .hero-contentMob {
    height: auto;
    display: flex;
    margin-bottom: 50px;

    @include respond-range(768px, 3000px) {
      display: none;
    }

    .hero-content-container {
      width: 100%;
      margin-top: 0;
      display: flex;
      justify-content: center;

      .hero-text-content {
        width: 100%;

        .hero-text-contentSec {
          display: flex;
          justify-content: center;
          width: 100%;
          z-index: 0;

          .hero-text-contentSecLeft {
            max-width: 1400px;
            width: 90%;
            margin-top: 7%;

            @include respond-range(320px, 1024px) {
              width: 100%;
              padding: 0 32px;
              margin-top: 20px;
            }

            .hero-title {
              color: #d0d0d0;
              font-family: Montserrat;
              font-size: 18px;
              font-weight: 600;
              letter-spacing: -0.48px;
              line-height: 29px;
            }

            .txt {
              line-height: 40px;
              margin-top: 1%;
              font-size: 34px;
            }

            .subTxt {
              font-weight: 300;
              line-height: 40px;
              font-size: 34px;
            }
          }
        }
      }
    }
  }

  .service-offeringContainerWeb {
    width: 100%;

    @include respond-range(320px, 767px) {
      display: none;
    }

    .service-offerSection {
      max-width: 835px;
      width: 58%;
      margin: auto;
      height: 208px;
      display: flex;
      justify-content: center;
      background-color: #353b48;
      align-items: center;
      flex-direction: column;
      position: absolute;
      bottom: -18%;
      left: 0;
      right: 0;

      @include respond-range(768px, 1024px) {
        bottom: -27%;
        width: 90%;
      }

      @include respond-range(1025px, 1300px) {
        bottom: -27%;
        width: 70%;
      }

      .textService {
        font-weight: 300;
        line-height: 46px;
        text-align: center;
        color: #ffffff;

        @include respond-range(768px, 1024px) {
          font-size: 25px;
          width: 80%;
          line-height: 32px;
        }

        @include respond-range(1025px, 1300px) {
          width: 80%;
        }
      }

      .service-offeringBtnSection {
        display: flex;
        margin-top: 30px;

        .service-offeringMenu {
          margin-right: 15px;
        }

        .ui.selection.dropdown {
          width: 340px;
          height: 44px !important;
          padding-top: 14px;

          @include respond-range(768px, 1024px) {
            width: 300px;
          }

          @include respond-range(1025px, 1300px) {
            width: 300px;
          }

          .dropdown.icon {
            padding-top: 15px;
          }
        }
      }
    }
  }

  .service-offeringContainerMob {
    width: 100%;

    @include respond-range(768px, 3000px) {
      display: none;
    }

    .service-offerSection {
      width: 100%;
      margin: auto;
      height: auto;
      display: flex;
      justify-content: center;
      background-color: #353b48;
      align-items: center;
      flex-direction: column;
      padding: 50px 32px;

      .textService {
        font-weight: 300;
        width: 100%;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
      }

      .service-offeringBtnSection {
        display: flex;
        margin-top: 30px;
        flex-direction: column;
        width: 100%;

        .serviceBtn-seperator {
          text-align: center;
          margin: 15px 0;
          color: #afafaf;
          font-size: 18px;
          line-height: 23px;
        }

        .ui.selection.dropdown {
          width: 100%;
          height: 44px !important;
          padding-top: 14px;

          .dropdown.icon {
            padding-top: 15px;
          }
        }
      }
    }
  }

  .serviceDes-section {
    height: 80vw;
    margin: auto;
    max-height: 750px;

    @include respond-range(320px, 767px) {
      height: auto;
      max-height: unset;
    }

    @include respond-range(768px, 1024px) {
      height: 90vw;
    }

    .serviceDes-section-mainContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      margin-top: 5%;

      @include respond-range(320px, 767px) {
        margin-top: 50px;
      }

      .serviceDes-sectionOver {
        width: 100%;
        max-width: 1400px;

        .serviceDes-text-background-containerLeft {
          position: absolute;
          display: flex;
          justify-content: center;

          @include respond-range(320px, 767px) {
            position: unset;
          }

          .serviceDes-text-contentLeft {
            float: left;
            width: 60%;
            position: relative;
            z-index: 2;
            height: 35%;
            margin-top: 350px;
            right: 0%;

            @include respond-range(320px, 767px) {
              margin-top: 0;
              width: 100%;
              padding: 0 32px;
            }

            @include respond-range(768px, 1024px) {
              margin-top: 32%;
              width: 90%;
            }

            @include respond-range(1025px, 1400px) {
              margin-top: 26%;
              width: 75%;
            }

            .serviceDesLeft {
              background-color: #fff;
              width: 164%;
              padding-top: 4%;
              max-width: 850px;

              @include respond-range(320px, 767px) {
                width: 100%;
              }

              @include respond-range(768px, 1024px) {
                width: 79%;
                max-width: none;
              }

              @include respond-range(1025px, 1400px) {
                max-width: none;
                width: 80%;
              }
            }

            .header {
              color: #452a49;
              font-weight: bold;
              line-height: 46px;

              @include respond-range(320px, 767px) {
                font-size: 32px;
                line-height: 42px;
              }
            }

            .txt-inner {
              margin-top: 3%;
              color: #000000;
              background: #fff;
              padding-right: 100px;
              line-height: 29px;

              @include respond-range(320px, 767px) {
                padding-right: 0px;
                font-size: 16px;
                line-height: 27px;
              }
            }

            .serviceDes-plusIcon {
              background-image: url("../images/bg-plus-row11@2x.png");
              background-repeat: no-repeat;
              background-size: contain;
              height: 14px;
              width: 494px;
              margin-top: 10%;

              @include respond-range(320px, 767px) {
                display: none;
              }
            }
          }

          .imgSec {
            top: 9%;
            left: 0%;
          }
        }

        .serviceDes-text-background-containerRight {
          width: 48%;
          height: 100%;
          float: right;

          @include respond-range(320px, 767px) {
            width: 100%;
            height: 75vw;
          }

          .serviceDes-text-contentRight {
            float: left;
            width: 100%;
            position: relative;
            height: 100%;
            top: 11%;
            margin-top: 5%;
            left: 23%;

            @include respond-range(320px, 767px) {
              position: unset;
              margin-top: 20px;
            }

            .serviceDes-plusIconTop {
              background-image: url("../images/bg-plus-row7@2x.png");
              background-repeat: no-repeat;
              background-size: contain;
              height: 14px;
              width: 60%;
              margin-bottom: 15%;
              position: relative;
              left: 29%;

              @include respond-range(320px, 767px) {
                display: none;
              }
            }

            .serviceDes-ImgContainer {
              background-image: url("../images/services-photo-allTypes@2x.jpg");
              background-repeat: no-repeat;
              background-size: 100%;
              height: 60%;
              width: 75%;

              @include respond-range(320px, 767px) {
                width: 100%;
                height: 100%;
              }

              @include respond-range(768px, 1024px) {
                width: 90%;
              }
            }
          }

          .imgSec {
            top: 9%;
            left: 0%;

            @include respond-range(768px, 1024px) {
              top: 12%;
            }
          }
        }
      }
    }
  }

  .ui.secondary.pointing.menu {
    width: 80%;
    margin: auto;
    max-width: 1400px;
    margin-top: 10%;
    border-bottom: unset;

    @include respond-range(320px, 1024px) {
      margin-top: 50px;
      width: 90%;
    }
  }

  .ui.secondary.pointing.menu .active.item {
    color: #452a49;
    font-size: 28px;
    line-height: 46px;
    border-color: #ff2255;
    padding: 10px 15px 10px 15px;

    @include respond-range(320px, 500px) {
      font-size: 16px;
      padding: 0 8px;
    }

    @include respond-range(501px, 767px) {
      font-size: 22px;
      padding: 0 8px;
    }
  }

  .ui.secondary.pointing.menu .active.item:hover {
    border-color: #ff2255;
    text-decoration: None !important;
    color: unset !important;
  }

  .ui.secondary.pointing.menu .item {
    color: #452a49;
    font-size: 28px;
    line-height: 46px;
    padding: 10px 15px 10px 15px;
    color: rgba(69, 42, 73, 0.3);

    @include respond-range(320px, 500px) {
      font-size: 16px;
      padding: 0 8px;
    }

    @include respond-range(501px, 767px) {
      font-size: 22px;
      padding: 0 8px;
    }
  }

  .serviceDes-defaultContainer {
    width: 100%;
    margin: 100px 0 10% 0;
    height: auto;

    @include respond-range(320px, 767px) {
      margin-top: 50px;
    }

    .serviceDesc-section {
      max-width: 1400px;
      margin: auto;
      width: 78%;

      @include respond-range(320px, 767px) {
        width: 100%;
        padding: 0 32px;
      }

      @include respond-range(768px, 1024px) {
        width: 90%;
      }

      .header {
        color: #452a49;
        line-height: 46px;

        @include respond-range(320px, 767px) {
          font-size: 32px;
          line-height: 42px;
        }
      }

      .paragraph {
        margin-top: 2%;

        @include respond-range(320px, 767px) {
          font-size: 16px;
          line-height: 27px;
          margin-top: 10px;
        }
      }

      .paraFinance {
        color: #000000;
        margin-top: 2%;
      }

      .semiHeader {
        margin-top: 5%;
        color: #34485e;
        line-height: 33px;

        @include respond-range(320px, 767px) {
          font-size: 26px;
          margin-top: 40px;
        }
      }

      .para {
        color: #787878;
      }

      .Dark {
        color: #000000;
        font-weight: 500;
        line-height: 29px;
      }

      .foreClosure-title {
        display: none;
        color: #353b48;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 20px;
      }

      .foreClosureServices {
        width: 100%;
        margin-top: 1%;

        @include respond-range(320px, 767px) {
          flex-direction: column;
        }

        .foreClosureText {
          width: 50%;

          @include respond-range(320px, 767px) {
            width: 100%;
          }

          .foreClosureTxt {
            color: #798fb9;
            font-family: Montserrat;
            font-size: 20px;
            line-height: 24px;
            text-align: left;

            @include respond-range(320px, 767px) {
              margin-top: 20px;
            }
          }

          .guarantee {
            margin-top: 15px;
            max-width: 360px;
          }

          .loanMod {
            margin-top: 3px;
          }
        }

        .closing {
          width: 40%;

          @include respond-range(320px, 767px) {
            width: 100%;
          }
        }

        .foreClosureButton {
          display: none;
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-start;

          @include respond-range(320px, 767px) {
            width: 100%;
            align-items: center;
            margin-top: 20px;
          }

          .aboutHiring-Sec {
            position: relative;
            margin-top: 3%;

            .contact {
              height: 44px;
            }

            .btn-Contact {
              width: 160px;
              text-align: left;
            }

            .icon_right {
              background-image: url("../images/btn-icon-arrowRight@2x.png");
              background-repeat: no-repeat;
              background-size: 35%;
              display: flex;
              height: 20px;
              width: 40px;
              position: absolute;
              top: 16px;
              right: -3px;
            }
          }
        }

        .Reo {
          margin-top: 7% !important;

          @include respond-range(320px, 767px) {
            margin-top: 3% !important;
          }
        }

        .closingBtn {
          width: 20%;

          @include respond-range(320px, 767px) {
            width: 100%;
          }

          @include respond-range(768px, 1024px) {
            width: 33.33%;
          }
        }

        .mod {
          margin-top: 6%;
        }
      }

      .foreClosureNationTxt {
        color: #787878;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        margin-top: 3%;

        @include respond-range(320px, 767px) {
          margin-top: 30px;
        }
      }

      .plusIcon {
        display: none;
        background-image: url("../images/background-tile@1x.png");
        height: 15px;
        width: 70px;
        margin: 10% 0;

        @include respond-range(320px, 767px) {
          display: none;
        }
      }

      .plusIconFinance {
        background-image: url("../images/background-tile@1x.png");
        height: 15px;
        width: 210px;
        margin: 5% 0;

        @include respond-range(320px, 767px) {
          display: none;
        }
      }

      .serviceIconPlus {
        margin-top: 10%;
        display: none;

        @include respond-range(320px, 767px) {
          display: none;
        }

        .serviceDes-plusIconTop {
          background-image: url("../images/bg-plus-row11@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          height: 14px;
          width: 494px;
          margin: auto;
        }
      }

      .financialImage {
        background-image: url("../images/tech-photo-finance@2x.jpg");
        background-repeat: no-repeat;
        background-size: 100%;
        height: 34vw;
        max-height: 610px;
        width: 100%;

        @include respond-range(320px, 767px) {
          margin-top: 5%;
        }
      }

      .realStateImage {
        background-image: url("../images/tech-photo-realestate@2x.jpg");
      }

      .servicerImage {
        background-image: url("../images/tech-photo-servicer@2x.jpg");
      }

      .consumerImage {
        background-image: url("../images/tech-photo-consumer@2x.jpg");
      }
    }
  }

  .aboutHiring-container {
    width: 100%;
    margin: 10% 0;

    @include respond-range(320px, 767px) {
      margin: 50px 0 60px 0;
    }

    .aboutHiring-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 62%;
      margin: auto;
      height: 100%;
      max-width: 1400px;
      width: 56%;

      @include respond-range(320px, 767px) {
        width: 100%;
        padding: 0 32px;
      }

      @include respond-range(768px, 1024px) {
        width: 90%;
      }

      .aboutHiring-Title {
        color: #ff2255;
        font-family: Montserrat;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: -0.39px;
        line-height: 29px;
        text-align: center;
      }

      .aboutHiring-desc {
        color: #353b48;
        font-family: Muli;
        font-size: 38px;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        margin-top: 2%;
        width: 100%;

        @include respond-range(320px, 767px) {
          margin-top: 15px;
          font-size: 28px;
        }
      }

      .aboutHiring-ContactSec {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 4%;
        width: 100%;

        @include respond-range(320px, 600px) {
          flex-direction: column;
          align-items: center;
          margin-top: 30px;
        }

        .aboutHiring-seperator {
          margin: 0 4%;
          text-align: center;
          display: flex;
          align-items: center;
          color: #afafaf;
          font-family: Muli;
          font-size: 18px;
          font-weight: 600;
          line-height: 23px;

          @include respond-range(320px, 600px) {
            margin: 15px 0;
            text-align: center;
          }
        }

        .aboutHiring-Sec {
          position: relative;

          .contact {
            height: 44px;
          }

          .btn-Contact {
            width: 160px;
            text-align: left;
          }

          .icon_right {
            background-image: url("../images/btn-icon-arrowRight@2x.png");
            background-repeat: no-repeat;
            background-size: 35%;
            display: flex;
            height: 20px;
            width: 40px;
            position: absolute;
            top: 16px;
            right: -3px;
          }
        }
      }
    }
  }

  .map-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f6f6f6;
    color: #452a49;
    padding: 75px 0;
    max-height: 800px;

    @include respond-max(976px) {
      padding: 7% 0;
    }

    .map-img {
      background-image: url("../images/licensed-states_2x (1).png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      max-width: 900px;
      max-height: 700px;
      width: 84vw;
      height: 68vw;
    }
  }
}

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
