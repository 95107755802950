﻿
@import "_constants.scss";

// Used to apply media queries
// At breakpoint-lg and if old-ie, just renders rule w/o media query
// ==========================================================
@mixin respond-min($width) {
    @if ($width == $breakpoint-lg) {
        @content;
    }
    @else {
        @media screen and (min-width: $width) {
            @content;
        }
    }
}

@mixin respond-max($width) {
	@if ($width == $breakpoint-lg) {
		@content;
	}
	@else {
		@media screen and (max-width: $width) {
			@content;
		}
	}
}

@mixin respond-range($min-width, $max-width) {
	@if ($min-width == $breakpoint-lg) {
		@content;
	}
	@else {
		@media screen and (min-width: $min-width) and (max-width: $max-width) {
			@content;
		}
	}
}


@mixin respond-min-height($height) {
	@if ($height == $breakpoint-lg) {
		@content;
	}
	@else {
		@media screen and (min-height: $height) {
			@content;
		}
	}
}

@mixin respond-max-height($height) {
	@if ($height == $breakpoint-lg) {
		@content;
	}
	@else {
		@media screen and (max-height: $height) {
			@content;
		}
	}
}

@mixin respond-range-height($min-height, $max-height) {
	@if ($min-height == $breakpoint-lg) {
		@content;
	}
	@else {
		@media screen and (min-height: $min-height) and (max-height: $max-height) {
			@content;
		}
	}
}
