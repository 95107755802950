@import "../modules/constants";
@import "../modules/mixins";

.techPage-Container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 5%;

  .techPage-hero-container {
    background-image: url("../images/tech-photo-hero@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vw;
    width: 100%;
    max-height: 600px;
    background-position: right;

    @include respond-range(320px, 600px) {
      height: 72vw;
      background-position: right;
    }
    @include respond-range(601px, 767px) {
      height: 60vw;
      background-position: right;
    }
    @include respond-range(768px, 1024px) {
      height: 70vw;
      max-height: 482px;
      background-position: right;
    }
    @include respond-range(1500px, 3000px) {
      max-height: 700px;
    }

    .hero-contentWeb {
      height: 70vh;
      @include respond-range(320px, 767px) {
        display: none;
      }

      .hero-content-container {
        width: 100%;
        margin-top: 0;
        display: flex;
        justify-content: center;

        .hero-text-content {
          width: 78%;
          @include respond-range(768px, 1024px) {
            width: 90%;
          }
          @include respond-range(320px, 767px) {
            width: 100%;
          }

          .hero-text-contentSec {
            display: flex;
            justify-content: center;
            width: 50%;
            position: absolute;
            z-index: 0;

            .hero-text-contentSecLeft {
              width: 100%;
              margin-top: 20%;

              .hero-title {
                color: #ffffff;
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 600;
                letter-spacing: -0.48px;
                line-height: 29px;
                @include respond-range(768px, 1024px) {
                  font-size: 18px;
                  line-height: 29px;
                }
              }
              .hero-title-transaction {
                color: #353b48;
                font-family: Muli;
                font-size: 56px;
                font-weight: 900;
                line-height: 65px;
                margin-top: 2%;
                @include respond-range(768px, 1024px) {
                  font-size: 34px;
                  line-height: 43px;
                }
              }
              .hero-paragraph {
                color: #353b48;
                font-family: Muli;
                font-size: 56px;
                font-weight: 300;
                line-height: 63px;
                width: 70%;
                @include respond-range(768px, 1024px) {
                  font-size: 34px;
                  line-height: 43px;
                  width: 80%;
                }
                @include respond-range(1025px, 1200px) {
                  font-size: 46px;
                  line-height: 50px;
                  width: 80%;
                }
              }
            }
          }
        }
      }
    }
  }

  .hero-contentMob {
    height: auto;
    margin-top: 4%;
    margin-bottom: 45px;
    @include respond-range(768px, 3000px) {
      display: none;
    }

    .hero-content-container {
      width: 100%;
      margin-top: 0;
      display: flex;
      justify-content: center;

      .hero-text-content {
        width: 100%;

        .hero-text-contentSec {
          display: flex;
          justify-content: center;
          width: 100%;
          z-index: 0;

          .hero-text-contentSecLeft {
            width: 100%;
            padding: 0 32px;

            .hero-title {
              color: #d0d0d0;
              font-family: Montserrat;
              font-size: 18px;
              font-weight: 600;
              letter-spacing: -0.27px;
              line-height: 29px;
            }
            .hero-title-transaction {
              color: #353b48;
              font-family: Muli;
              font-size: 34px;
              font-weight: 900;
              line-height: 40px;
            }
            .hero-paragraph {
              color: #353b48;
              font-family: Muli;
              font-size: 34px;
              font-weight: 300;
              line-height: 40px;
              width: 80%;
            }
          }
        }
      }
    }
  }
  .platform-section {
    height: 130vw;
    max-height: 970px;
    background-color: #353b48;
    @include respond-range(320px, 767px) {
      height: auto;
      max-height: unset;
    }
    @include respond-range(768px, 960px) {
      height: 160vw;
      max-height: 1200px;
    }
    @include respond-range(961px, 1024px) {
      max-height: 1000px;
    }
    @include respond-range(1025px, 1600px) {
      height: 120vw;
      max-height: 920px;
    }

    .platform-section-mainContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      .platform-sectionOver {
        width: 78%;
        @include respond-range(768px, 1024px) {
          width: 90%;
          margin-bottom: 90px;
        }
        @include respond-range(320px, 767px) {
          width: 100%;
          padding-bottom: 50px;
        }

        .platform-text {
          display: flex;
          // justify-content: center;
          width: 100%;
          z-index: 0;
          background: #353b48;
          max-width: 1400px;
          margin: auto;
          @include respond-range(320px, 767px) {
            width: 100%;
            flex-direction: column;
            position: unset;
          }
          @include respond-range(768px, 1024px) {
            width: 100%;
            position: unset;
          }

          .platform-text-left {
            width: 100%;
            margin-top: 115px;
            position: relative;
            @include respond-range(320px, 767px) {
              margin-top: 0%;
            }
            @include respond-range(768px, 1024px) {
              width: 100%;
              margin-top: 115px;
            }
            .platform-text-leftSec {
              width: 100%;
              position: absolute;
              left: 10%;
              @include respond-range(320px, 767px) {
                width: 100%;
                margin: auto;
                padding: 0 32px;
                position: unset;
              }
              @include respond-range(768px, 1024px) {
                width: 100%;
              }
              @include respond-range(1025px, 1300px) {
                width: 90%;
              }

              .platform-heading {
                color: #ffffff;
                font-family: Muli;
                font-size: 38px;
                font-weight: 800;
                line-height: 46px;
                @include respond-range(320px, 1024px) {
                  font-size: 32px;
                  line-height: 42px;
                }
              }

              .platform-subHeading {
                color: #ffffff;
                font-family: Muli;
                font-size: 26px;
                font-weight: bold;
                line-height: 33px;
                margin-top: 5%;
                @include respond-range(320px, 1024px) {
                  font-size: 20px;
                  line-height: 32px;
                  margin-top: 40px;
                }
              }

              .platform-desc {
                color: #cfcfcf;
                font-family: Montserrat;
                font-size: 17px;
                font-weight: 300;
                line-height: 29px;
                margin-top: 5%;
                max-width: 83%;
                @include respond-range(320px, 767px) {
                  max-width: 100%;
                  margin-top: 20px;
                  font-size: 16px;
                  line-height: 27px;
                }
                @include respond-range(768px, 1024px) {
                  font-size: 16px;
                  line-height: 27px;
                }
              }
              .platform-desc-subHead {
                max-width: 95%;
                width: 50%;
                @include respond-range(320px, 960px) {
                  max-width: 100%;
                  width: 100%;
                }
                @include respond-range(961px, 1024px) {
                  max-width: 45%;
                  width: 50%;
                }
                li::before {
                  color: #fdfdfd;
                  transform: scale(2.5);
                }

                .platform-description {
                  display: flex;
                  color: #cfcfcf;
                  font-family: Montserrat;
                  font-size: 17px;
                  font-weight: 300;
                  line-height: 29px;

                  .platform-subHeadDot {
                    height: 7px;
                    width: 24px;
                    background-color: #fdfdfd;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-top: 8px;
                  }
                }
                .nation {
                  margin-top: 6%;
                  @include respond-range(320px, 767px) {
                    margin-top: 3%;
                  }
                }
              }
              .platform-descInner {
                max-width: 100%;
                @include respond-range(320px, 1024px) {
                  max-width: 100%;
                  margin-bottom: 30px;
                }
                .platform-descSubMain {
                  display: flex;
                  @include respond-range(320px, 960px) {
                    flex-direction: column;
                  }
                }
              }

              .platform-downloadSignInSection {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 10%;
                height: 51px;
                max-width: 450px;
                min-width: 450px;
                @include respond-range(320px, 767px) {
                  flex-direction: column;
                  height: 140px;
                  align-items: center;
                  margin: auto;
                  min-width: unset;
                }
                @include respond-range(768px, 960px) {
                  position: absolute;
                  left: -25%;
                }
                @include respond-range(768px, 1024px) {
                  flex-direction: column;
                  height: 160px;
                  align-items: center;
                  margin: auto;
                }

                @include respond-range(1025px, 1439px) {
                  width: 105%;
                  height: 44px;
                }

                .platform-iconDownload {
                  background-image: url("../images/Artboard@2x.png");
                  background-repeat: no-repeat;
                  background-size: 100%;
                  height: 100%;
                  width: 132px;

                  @include respond-range(768px, 1023px) {
                    margin-top: 3%;
                  }
                }

                .platform-seperator {
                  margin: 0 4%;
                  color: #afafaf;
                  font-family: Muli;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 23px;
                  text-align: center;
                  align-items: center;
                  display: flex;
                  @include respond-range(320px, 600px) {
                    margin-bottom: 5%;
                  }
                  @include respond-range(601px, 1024px) {
                    margin-bottom: 3%;
                  }
                }

                .WorkTogether-Sec {
                  position: relative;

                  .contact {
                    height: 44px;
                    position: relative;
                  }
                  .btn-Contact {
                    width: 140px;
                    text-align: left;
                  }
                  .icon_right {
                    background-image: url("../images/btn-icon-arrowRight@2x.png");
                    background-repeat: no-repeat;
                    background-size: 35%;
                    display: flex;
                    height: 20px;
                    width: 40px;
                    position: absolute;
                    top: 16px;
                    right: 0px;
                  }
                }
                .appleDownload {
                  width: 132px;
                  background: #000000;
                }
              }
            }
          }

          .platform-carousel {
            height: 90%;
            @include respond-range(320px, 767px) {
              margin: auto;
            }
            @include respond-range(768px, 1024px) {
              display: flex;
            }

            .platform-carouselRight {
              float: right;
              position: relative;
              z-index: 2;
              height: 100%;
              top: 19px;
              right: 0%;
              @include respond-range(320px, 767px) {
                float: left;
                top: 0;
                right: 0;
                margin-top: 20px;
              }
              @include respond-range(768px, 790px) {
                right: 10%;
                margin: auto;
              }

              .slider-container {
                height: 661px;
                width: 305px;
                float: right;
                margin-top: 34%;
                @include respond-range(320px, 767px) {
                  margin-top: 10%;
                  height: 650px;
                }

                ul {
                  position: relative;

                  li {
                    margin: 5px;
                    height: 10px;
                    border-radius: 8px;
                  }

                  button {
                    width: 5px;
                    height: 100%;
                    border-radius: 8px;
                    background-color: #fcfcfc;
                  }

                  button:before {
                    content: none;
                  }

                  .slick-active {
                    background-color: #fcfcfc;

                    button {
                      background-color: #ff2255;
                      width: 100%;
                      transition: width 3000ms ease-in-out;
                    }
                  }
                }

                .img {
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: 50%;
                  width: 310px;
                  height: 545px;
                  outline: none;
                }
                .slide-1 {
                  background-image: url("../images/sfr-slide-min.png");
                }
                .slide-2 {
                  background-image: url("../images/hot-properties-slide-min.png");
                }
                .slide-3 {
                  background-image: url("../images/farm-slide-min.png");
                }
                .slide-4 {
                  background-image: url("../images/tech-slide-3-min.png");
                }
              }
            }
          }
        }
      }
    }
  }

  .farming-section {
    height: 65vw;
    max-height: 1000px;
    @include respond-range(320px, 767px) {
      height: auto;
      max-height: 1500px;
    }
    @include respond-range(768px, 1024px) {
      height: 70vw;
      max-height: 650px;
      margin-bottom: 100px;
    }
    @include respond-range(1025px, 1200px) {
      height: 65vw;
      max-height: 500px;
      margin-bottom: 100px;
    }
    @include respond-range(1201px, 1450px) {
      height: 65vw;
      max-height: 600px;
      margin-bottom: 100px;
    }
    @include respond-range(1451px, 1700px) {
      height: 70vw;
      max-height: 700px;
      margin-bottom: 100px;
    }
    @include respond-range(1701px, 3000px) {
      height: 70vw;
      max-height: 900px;
    }

    .farming-section-mainContainer {
      width: 78%;
      height: 100%;
      display: flex;
      margin: auto;
      justify-content: center;
      @include respond-range(768px, 1024px) {
        width: 90%;
        margin: auto;
      }
      @include respond-range(320px, 767px) {
        width: 100%;
        margin: auto;
      }

      .farming-sectionOver {
        width: 100%;
        max-width: 1400px;
        @include respond-range(320px, 767px) {
          display: flex;
          flex-direction: column;
        }

        .farming-ImgContainer {
          display: flex;
          justify-content: center;
          width: 70%;
          position: absolute;
          z-index: 2;
          height: 100%;
          @include respond-range(320px, 767px) {
            width: 100%;
            position: unset;
            height: 80%;
          }
          @include respond-range(768px, 1024px) {
            height: 70%;
          }
          .farming-Img-left {
            margin-top: 8%;
            height: 50vw;
            @include respond-range(320px, 767px) {
              height: 140vw;
              margin-top: 40px;
            }
            @include respond-range(768px, 1024px) {
              position: relative;
              top: 8%;
              margin-top: 0;
            }

            .farming-Img-leftSec {
              background-image: url("../images/tech-graphic-farming@2x.png");
              background-repeat: no-repeat;
              background-size: 50%;
              height: 100%;
              width: 100%;
              @include respond-range(320px, 767px) {
                background-size: 100%;
                height: 130%;
              }
              @include respond-range(768px, 1024px) {
                background-size: contain;
                height: 130%;
              }
            }
          }
        }
        .farming-text-background-container {
          width: 100%;
          height: 58vw;
          float: right;
          @include respond-range(320px, 767px) {
            height: auto;
            margin-top: 30px;
          }
          @include respond-range(768px, 1024px) {
            height: 90vw;
          }

          .farming-text-content {
            float: right;
            width: 36%;
            position: relative;
            z-index: 2;
            height: 100%;
            top: 11%;
            margin-top: 10%;
            right: 3%;
            @include respond-range(320px, 767px) {
              top: 170%;
              width: 100%;
              padding: 0 32px;
              right: 0;
              float: none !important;
              margin: auto;
              position: unset;
            }
            @include respond-range(768px, 1024px) {
              margin-top: 0;
            }

            .farming-header-container {
              display: flex;
              justify-content: space-between;

              .farming-lighter-header {
                color: #353b48;
                font-family: Muli;
                font-size: 26px;
                font-weight: bold;
                line-height: 33px;
                @include respond-range(320px, 767px) {
                  font-size: 20px;
                  line-height: 32px;
                }
              }
              .btn-green {
                height: 31px;
              }
              .btn-grey {
                height: 31px;
              }
            }
            .farming-desc {
              color: #000000;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 300;
              line-height: 29px;
              margin-top: 3%;
              @include respond-range(320px, 767px) {
                margin-top: 20px;
              }
            }

            .farming-text-subSection {
              color: #798fb9;
              font-family: Muli;
              font-size: 21px;
              font-weight: bold;
              line-height: 26px;
              margin-top: 6%;
              @include respond-range(320px, 767px) {
                font-size: 20px;
                line-height: 25px;
                margin-top: 30px;
              }
            }
            .txt-inner {
              margin-top: 3%;
              color: #787878;
              @include respond-range(320px, 767px) {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  .features-section {
    height: auto;

    @include respond-range(320px, 767px) {
      padding-top: 20px;
      height: auto;
    }
    @include respond-range(768px, 1024px) {
      height: auto;
    }

    .features-section-mainContainer {
      width: 78%;
      height: 100%;
      display: flex;
      justify-content: center;
      margin: auto;
      padding: 4% 0;
      @include respond-range(768px, 1024px) {
        width: 90%;
        margin: auto;
      }
      @include respond-range(320px, 767px) {
        width: 100%;
        margin: auto;
      }

      .features-sectionOver {
        width: 100%;
        display: flex;
        max-width: 1400px;
        @include respond-range(320px, 767px) {
          flex-direction: column;
        }

        .right {
          @include respond-range(768px, 1024px) {
            margin-right: 0 !important;
            margin-left: 5%;
          }
        }
        .features-text-background-container {
          width: 100%;
          float: right;
          @include respond-range(320px, 767px) {
            margin: auto;
            width: 100%;
            padding: 0 32px;
          }
          @include respond-range(768px, 1024px) {
            width: 45%;
            margin-right: 5%;
          }

          .features-text-contentLeft {
            float: left;
            width: 78%;
            position: relative;
            z-index: 2;
            height: 100%;
            top: 11%;
            margin-top: 10%;
            right: 0%;
            @include respond-range(320px, 767px) {
              width: 100%;
              margin-top: 0;
            }
            @include respond-range(768px, 1024px) {
              width: 100%;
              top: 0;
              margin-top: 0;
            }

            .features-header-container {
              display: flex;
              justify-content: space-between;

              .features-lighter-header {
                color: #353b48;
                font-family: Muli;
                font-size: 26px;
                font-weight: bold;
                line-height: 33px;
                @include respond-range(320px, 767px) {
                  font-size: 20px;
                  line-height: 32px;
                }
              }
              .btn-small {
                height: 31px;
              }
            }

            .features-desc {
              color: #000000;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 300;
              line-height: 29px;
              margin-top: 3%;
              @include respond-range(320px, 767px) {
                margin-top: 20px;
              }
            }

            .features-text-subSection {
              color: #798fb9;
              font-family: Muli;
              font-size: 21px;
              font-weight: bold;
              line-height: 26px;
              margin-top: 6%;
              @include respond-range(320px, 767px) {
                font-size: 20px;
                line-height: 25px;
                margin-top: 30px;
              }
            }
            .txt-inner {
              margin-top: 3%;
              color: #787878;
              @include respond-range(320px, 767px) {
                margin-top: 15px;
              }
            }
          }
          .bottomSecLeft {
            top: 0;
          }

          .features-text-contentRight {
            float: left;
            width: 78%;
            position: relative;
            z-index: 2;
            top: 11%;
            margin-top: 10%;
            left: 20%;
            @include respond-range(320px, 767px) {
              width: 100%;
              margin: auto;
              left: 0;
              padding-top: 55px;
            }
            @include respond-range(768px, 1024px) {
              width: 100%;
              left: 0%;
              top: 0;
              margin-top: 0;
            }

            .features-header-container {
              display: flex;
              justify-content: space-between;

              .features-lighter-header {
                color: #353b48;
                font-family: Muli;
                font-size: 26px;
                font-weight: bold;
                line-height: 33px;
                @include respond-range(320px, 767px) {
                  font-size: 20px;
                  line-height: 32px;
                }
              }
            }
            .features-desc {
              color: #000000;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 300;
              line-height: 29px;
              margin-top: 3%;
              @include respond-range(320px, 767px) {
                margin-top: 20px;
              }
            }

            .features-text-subSection {
              color: #798fb9;
              font-family: Muli;
              font-size: 21px;
              font-weight: bold;
              line-height: 26px;
              margin-top: 6%;
              @include respond-range(320px, 767px) {
                font-size: 20px;
                line-height: 25px;
                margin-top: 30px;
              }
            }
            .txt-inner {
              margin-top: 3%;
              color: #787878;
              @include respond-range(320px, 767px) {
                margin-top: 15px;
              }
            }

            .features-ImgContainer {
              background-image: url("../images/tech-graphic-closing@2x.png");
              background-repeat: no-repeat;
              background-size: 100%;
              height: 60%;
              width: 115%;
            }
          }
          .imgSec {
            left: 9%;
            top: -9%;
            height: 120%;
            @include respond-range(768px, 1024px) {
              top: 10%;
            }
          }
        }
      }
    }
  }
  .down {
    margin: 4% 0;
    @include respond-range(320px, 767px) {
      margin-top: 100px;
    }
    @include respond-range(768px, 1024px) {
      margin-bottom: 8%;
    }
  }

  .integration-section {
    padding-top: 8%;
    padding-bottom: 8%;
    max-height: 850px;
    background-color: #fafafa;
    @include respond-range(320px, 767px) {
      padding: 50px 0;
    }
    @include respond-range(768px, 1024px) {
      padding: 50px 0;
    }

    .integration-section-mainContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      max-width: 1400px;
      margin: auto;

      .integration-sectionOver {
        width: 78%;
        display: flex;
        flex-direction: column;
        margin: auto;
        @include respond-range(320px, 767px) {
          width: 100%;
          padding: 0 32px;
        }
        @include respond-range(768px, 1024px) {
          width: 90%;
        }
        @include respond-range(1025px, 1550px) {
          width: 78%;
        }

        .integration-integrations {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .mainTxt {
            @include respond-range(320px, 767px) {
              font-size: 32px;
              line-height: 42px;
            }
          }
          .integration-desc {
            color: #000000;
            font-family: Montserrat;
            font-size: 17px;
            font-weight: 300;
            line-height: 29px;
            margin-top: 3%;
            width: 80%;
            text-align: center;
            @include respond-range(320px, 767px) {
              width: 100%;
              margin-top: 20px;
            }
          }
          .innerSub {
            color: #34485e;
            font-weight: bold;
            line-height: 33px;
            margin-top: 30%;
          }
          .txt-inner {
            margin-top: 3%;
            color: #787878;
            @include respond-range(320px, 767px) {
              margin-top: 15px;
            }
          }
        }

        .integration-thirdPartyMain {
          display: flex;
          width: 100%;
          margin-top: 10%;
          justify-content: space-between;
          @include respond-range(320px, 767px) {
            flex-direction: column;
            margin-top: 30px;
          }

          .integration-thirdParty {
            width: 40%;
            margin-right: 5%;
            @include respond-range(320px, 767px) {
              margin-right: 0;
              width: 100%;
            }
            .txt-inner {
              margin-top: 3%;
              color: #787878;
              @include respond-range(320px, 767px) {
                margin-top: 15px;
              }
            }
          }

          .integration-houseSystem {
            width: 45%;
            margin-left: 5%;
            @include respond-range(320px, 767px) {
              margin-left: 0;
              width: 100%;
              margin-top: 30px;
            }
            .innerSubRight {
            }
            .txt-inner {
              margin-top: 3%;
              color: #787878;
              @include respond-range(320px, 767px) {
                margin-top: 15px;
              }
            }
          }
        }

        .integration-contactUs {
          display: flex;
          justify-content: center;
          margin-top: 10%;
          @include respond-range(320px, 767px) {
            display: none;
          }
          .WorkTogether-Sec {
            position: relative;

            .contact {
              height: 44px;
              width: 247px;
              position: relative;
            }
            .btn-Contact {
              width: 180px;
              text-align: left;
            }
            .icon_right {
              background-image: url("../images/btn-icon-arrowRight@2x.png");
              background-repeat: no-repeat;
              background-size: 35%;
              display: flex;
              height: 20px;
              width: 40px;
              position: absolute;
              top: 16px;
              right: 0px;
            }
          }
        }
      }
    }
  }
  .security-section {
    height: 1000px;
    max-width: 1400px;
    margin: auto;
    @include respond-range(320px, 767px) {
      height: auto;
      margin-bottom: 60px;
    }
    @include respond-range(768px, 1024px) {
      margin-bottom: 130px;
      height: auto;
    }
    .securityCompllianceSec {
      width: 100%;
      height: 300px;
      justify-content: center;
      margin-top: 9%;
      @include respond-range(320px, 767px) {
        padding-top: 15%;
        margin: auto;
        width: 100%;
        padding: 0 32px;
        height: auto;
        margin: 50px 0 40px 0;
      }
      @include respond-range(768px, 1024px) {
        margin-top: 50px;
        height: auto;
        margin-bottom: 15px;
      }

      .comHeader {
        color: #452a49;
        text-align: center;
        font-weight: 900;
        @include respond-range(320px, 767px) {
          font-size: 32px;
          line-height: 38px;
          text-align: left;
        }
      }
      .security-subHeading {
        color: #452a49;
        font-family: Muli;
        font-size: 38px;
        font-weight: 300;
        line-height: 48px;
        text-align: center;
        margin-top: 5px;
        @include respond-range(320px, 767px) {
          font-size: 32px;
          line-height: 38px;
          text-align: left;
        }
      }
      .security-desc {
        margin-top: 2%;
        width: 56%;
        text-align: center;
        margin: auto;
        padding-top: 2%;
        @include respond-range(320px, 767px) {
          width: 100%;
          text-align: left;
        }
        @include respond-range(768px, 1024px) {
          width: 100%;
          text-align: left;
          width: 85%;
          font-size: 16px;
          line-height: 27px;
        }
      }
    }

    .security-section-mainContainer {
      width: 100%;
      height: 62%;
      display: flex;
      justify-content: center;
      @include respond-range(320px, 767px) {
        margin: auto;
      }

      .security-sectionOver {
        width: 78%;
        display: flex;
        @include respond-range(320px, 767px) {
          width: 100%;
          flex-direction: column;
        }
        @include respond-range(768px, 1024px) {
          width: 85%;
        }

        .security-text-background-container {
          width: 100%;
          height: 500px;
          float: right;
          @include respond-range(320px, 550px) {
            height: auto;
            width: 100%;
          }
          @include respond-range(551px, 767px) {
            height: auto;
            max-width: 600px;
            width: 90%;
            margin: auto;
          }

          .security-text-contentLeft {
            float: left;
            width: 92%;
            position: relative;
            z-index: 2;
            height: 100%;
            top: 0%;
            margin-top: 5%;
            left: 4%;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            background-color: #fcfcfc;
            padding: 8%;
            @include respond-range(320px, 767px) {
              left: 0;
              width: 100%;
              padding: 16% 32px;
            }
            @include respond-range(768px, 1024px) {
              padding: 4%;
            }

            .security-complianceIcon {
              background-image: url("../images/icon-compliance@2x.png");
              background-repeat: no-repeat;
              background-size: 100%;
              height: 65px;
              width: 64px;
              margin: auto;
            }
            .security-complianceText {
              color: #34406b;
              font-family: Muli;
              font-size: 30px;
              font-weight: bold;
              line-height: 38px;
              text-align: center;
              margin-top: 30px;
            }
            .txt-inner {
              margin-top: 3%;
              color: #999999;
              text-align: center;
              margin-top: 20px;
              @include respond-range(320px, 767px) {
                margin-top: 15px;
              }
            }
          }
          .bottomSecLeft {
            top: 0;
          }

          .security-text-contentRight {
            float: left;
            width: 92%;
            position: relative;
            z-index: 2;
            height: 100%;
            margin-top: 5%;
            left: 6%;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            background-color: #fcfcfc;
            padding: 8%;
            @include respond-range(320px, 767px) {
              left: 0;
              width: 100%;
              padding: 16% 32px;
              margin-top: 30px;
            }
            @include respond-range(768px, 1024px) {
              padding: 4%;
            }

            .security-securityIcon {
              background-image: url("../images/icon-security@2x.png");
              background-repeat: no-repeat;
              background-size: 100%;
              height: 65px;
              width: 45px;
              margin: auto;
            }
            .security-securityText {
              color: #34406b;
              font-family: Muli;
              font-size: 30px;
              font-weight: bold;
              line-height: 38px;
              text-align: center;
              margin-top: 30px;
            }

            .txt-inner {
              margin-top: 3%;
              color: #999999;
              text-align: center;
              margin-top: 20px;
              @include respond-range(320px, 767px) {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}
