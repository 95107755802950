﻿
//---------------------------------
//fonts
//---------------------------------

$font-sans: 'Open Sans', 'sans-serif';

//---------------------------------
//colors 
//---------------------------------

$lightest: #FFF;
$light-gray: #a6b1b9;
$light-tan: #FAF9F0;
$hot-pink: #FF2255;
$darkest-blue: #353B48;
$orange: #F6490D;
$deep-purple: #452A49;
$lime-green: #5AE995;

//-------------------------
$text-lightest: $lightest;
$light-divider-lines: $light-gray;

//---------------------------------
//breakpoints
//---------------------------------

$breakpoint-xxsm: 320px;
$breakpoint-xsm: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-sml: 1440px;
$breakpoint-xl: 1600px;
$breakpoint-xxl: 1920px;