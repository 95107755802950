@import "../modules/constants";
@import "../modules/mixins";

.contact-Container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 5%;

  .navbar-hero-container {
    background-image: url("../images/contact-photo-hero@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vw;
    width: 100%;
    max-height: 600px;
    background-position: bottom;

    @include respond-range(320px, 1024px) {
      height: 75vw;
      background-size: cover;
      background-position: top;
      max-height: 500px;
    }
  }

  .offices-tabs {
    display: flex;
    margin-top: 10%;
  }

  .office-tab {
    font-size: 16px;
    cursor: pointer;

    @include respond-max($breakpoint-xsm) {
      font-size: 12px;
    }

    &.active {
      text-decoration: underline;
      color: $hot-pink;
    }
  }

  .office-tab_margin {
    margin: 0 20px;
  }

  .contact-hero-container {
    .hero-content {
      height: 42vw;

      .hero-content-container {
        width: 100%;
        height: 100%;
        margin-top: 0;
        display: flex;
        justify-content: center;

        .hero-text-content {
          width: 100%;

          .hero-text-contentSec {
            display: flex;
            justify-content: center;
            width: 100%;
            z-index: 0;
            max-width: 1400px;
            margin: auto;

            .hero-text-contentSecLeftWeb {
              width: 80%;
              margin-top: 12%;
              text-align: right;

              @include respond-range(320px, 767px) {
                width: 100%;
                padding: 0 32px;
                display: none;
              }

              @include respond-range(768px, 1550px) {
                width: 78%;
              }

              .hero-title {
                color: #cfcfcf;
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 600;
                letter-spacing: -0.48px;
                line-height: 29px;
                padding-right: 140px;

                @include respond-range(320px, 767px) {
                  font-size: 26px;
                }

                @include respond-range(320px, 600px) {
                  padding-right: 0;
                }

                @include respond-range(601px, 767px) {
                  padding-right: 30px;
                }

                @include respond-range(768px, 1024px) {
                  font-size: 26px;
                  padding-right: 50px;
                }
              }

              .hero-title-transaction {
                color: #353b48;
                font-family: Muli;
                font-size: 56px;
                font-weight: 300;
                line-height: 70px;
                margin-top: 1%;

                @include respond-range(320px, 767px) {
                  font-size: 34px;
                  line-height: 38px;
                }

                @include respond-range(768px, 1024px) {
                  font-size: 34px;
                  line-height: 43px;
                }
              }

              .hero-paragraph {
                color: #353b48;
                font-family: Muli;
                font-size: 56px;
                font-weight: 900;
                line-height: 56px;

                @include respond-range(320px, 767px) {
                  font-size: 34px;
                  line-height: 38px;
                }

                @include respond-range(768px, 1024px) {
                  font-size: 34px;
                  line-height: 43px;
                }
              }

              .contact-heroPlusSign {
                background-image: url("../images/bg-plus-row6@2x.png");
                background-repeat: repeat;
                background-size: contain;
                height: 14px;
                width: 254px;
                margin-top: 10%;
              }

              .contact-FinTechDesc {
                color: #fafbfd;
                font-family: Montserrat;
                font-size: 36px;
                line-height: 69px;
                max-width: 50%;
                margin-top: 10%;
              }
            }
          }
        }
      }
    }
  }

  .hero-text-contentSecLeftMob {
    width: 100%;
    padding: 0 32px;
    margin-top: 60px;

    @include respond-range(601px, 767px) {
      width: 60%;
      margin: auto;
      padding: 0;
      margin-top: 60px;
    }

    @include respond-range(768px, 3000px) {
      display: none;
    }

    .hero-title {
      color: #cfcfcf;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.48px;
      line-height: 29px;
    }

    .hero-title-transaction {
      color: #353b48;
      font-family: Muli;
      font-size: 56px;
      font-weight: 300;
      line-height: 70px;
      margin-top: 1%;

      @include respond-range(320px, 767px) {
        font-size: 34px;
        line-height: 38px;
      }
    }

    .hero-paragraph {
      color: #353b48;
      font-family: Muli;
      font-size: 56px;
      font-weight: 900;
      line-height: 56px;

      @include respond-range(320px, 767px) {
        font-size: 34px;
        line-height: 38px;
      }
    }
  }

  .contactForm-container {
    margin-bottom: 10%;

    @include respond-range(320px, 767px) {
      margin-top: 60px;
    }

    @include respond-range(768px, 1024px) {
      margin-top: 40px;
      margin-bottom: 50px;
    }

    .contactForm-Note {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 837px;
      height: auto;
      margin: auto;
      margin-top: 8%;
      flex-direction: column;
      position: relative;

      @include respond-range(320px, 767px) {
        height: auto;
        width: 100%;
        margin-top: 0;
      }

      @include respond-range(768px, 1024px) {
        width: 90%;
        margin-top: 0;
      }

      .resp-message {
        position: absolute;
        bottom: 291px;
        color: #353b48;
        font-weight: 700;
        text-align: center;
        width: 90%;
      }

      .noteText {
        color: #353b48;
        font-size: 44px;
        line-height: 46px;

        @include respond-range(320px, 767px) {
          font-size: 32px;
          line-height: 42px;
        }
      }

      .contact-inputNoteSec {
        width: 100%;
        display: flex;
        margin-top: 10%;

        @include respond-range(320px, 767px) {
          flex-direction: column;
          margin-top: 15px;
        }

        @include respond-range(768px, 1024px) {
          margin-top: 30px;
        }

        .contact-inputNoteSecLeft {
          width: 40%;
          margin-right: 40px;

          @include respond-range(320px, 600px) {
            width: 100%;
            margin: auto;
            padding: 0 32px;
          }

          @include respond-range(601px, 767px) {
            width: 60%;
            margin: auto;
          }

          @include respond-range(768px, 1024px) {
            width: 50%;
            margin-right: 5%;
          }

          .name-input {
            height: 44px;
            width: 340px;
            padding-left: 10px;
            font-family: Montserrat;
            font-size: 14px;
            line-height: 18px;
            border: 1px solid #ebebeb;
            border-radius: 3px 0 0 3px;
            background-color: #ffffff;
            margin-bottom: 7%;

            @include respond-range(320px, 767px) {
              width: 100%;
              margin-bottom: 30px;
            }

            @include respond-range(768px, 1024px) {
              width: 100%;
            }
          }

          .dropdown {
            height: auto;
          }
        }

        .contact-inputNoteSecRight {
          width: 60%;

          @include respond-range(320px, 600px) {
            width: 100%;
            padding: 0 32px;
          }

          @include respond-range(601px, 767px) {
            width: 60%;
            margin: auto;
          }

          @include respond-range(768px, 1024px) {
            width: 50%;
          }

          .contact-TextArea {
            box-sizing: border-box;
            height: 182px;
            width: 462px;
            border: 1px solid #ebebeb;
            border-radius: 3px;
            background-color: #ffffff;
            padding: 15px;

            @include respond-range(320px, 767px) {
              width: 100%;
            }

            @include respond-range(768px, 1024px) {
              width: 100%;
            }
          }

          .aboutHiring-Sec {
            position: relative;
            width: 100%;
            margin-top: 4%;

            @include respond-range(320px, 767px) {
              margin-top: 25px;
            }

            .contact {
              height: 44px;
              width: 143px;
              position: absolute;
              right: 0;

              @include respond-range(320px, 767px) {
                left: 0;
              }
            }

            .btn-Contact {
              width: 75%;
              text-align: left;
            }

            .icon_right {
              background-image: url("../images/btn-icon-arrowRight@2x.png");
              background-repeat: no-repeat;
              background-size: 35%;
              display: flex;
              height: 20px;
              width: 40px;
              position: absolute;
              top: 16px;
              right: -3px;
            }
          }
        }
      }

      .contact-plusSignSeparator {
        height: 54px;
        width: 476px;
        background-image: url("../images/bg-plus-2row11@2x.png");
        background-size: contain;
        margin-top: 12%;

        @include respond-range(320px, 767px) {
          display: none;
        }

        @include respond-range(320px, 460px) {
          margin-top: 22%;
          background-size: cover;
          height: 33px;
          width: 70%;
        }

        @include respond-range(768px, 1024px) {
          margin-top: 5%;
        }
      }
    }
  }

  .headQuaters-container {
    margin-top: 10%;
    margin-bottom: 10%;

    @include respond-range(320px, 767px) {
      margin-top: 90px;
      margin-bottom: 30px;
    }

    @include respond-range(768px, 1024px) {
      margin-top: 0;
    }

    .headQuatersMain {
      display: flex;
      width: 100%;
      margin: auto;
      max-width: 1400px;

      @include respond-range(320px, 767px) {
        flex-direction: column;
        width: 100%;
      }

      @include respond-range(768px, 1024px) {
        width: 90%;
        flex-direction: column;
      }

      .background-map_headquarters {
        background-image: url("../images/contact-map-bg@2x.jpg");
      }

      .background-map_dallas {
        background-image: url("../images/graphic-dallasOffice.jpg");
      }

      .background-map_coraopolis {
        background-image: url("../images/graphic-coraopolisOffice.jpg");
      }

      .headQuatersMainLeft {
        height: auto;
        transition: background-image ease 0.5s;
        background-size: cover;
        width: 50%;
        padding: 9% 10%;

        @include respond-range(320px, 767px) {
          width: 100%;
          padding: 25px 0;
          display: flex;
          justify-content: center;
          background-image: unset;
        }

        @include respond-range(768px, 1024px) {
          width: 100%;
          padding: 15% 20%;
          background-size: contain;
        }

        .headQuaters-textSec {
          display: flex;
          flex-direction: column;

          @include respond-range(320px, 767px) {
            margin-top: 0;
            padding: 0 32px;
          }

          @include respond-range(768px, 1024px) {
            margin-top: 0%;
          }

          .headQuaters-buttonSec {
            display: flex;
            flex-direction: row;
            margin-top: 9%;

            @include respond-range(320px, 767px) {
              flex-direction: column;
              margin-top: 10px;
            }

            @include respond-range(768px, 1024px) {
              flex-direction: row;
            }

            @include respond-range(1025px, 1300px) {
              flex-direction: column;
            }

            .WorkTogether-Sec {
              position: relative;
              margin-right: 10px;

              @include respond-range(320px, 767px) {
                margin-right: 0;
              }

              .contact {
                height: 44px;
                width: 196px;
                position: relative;

                @include respond-range(320px, 767px) {
                  width: 254px;
                }
              }

              .btn-Contact {
                width: 160px;
                text-align: left;
              }

              .icon_right {
                background-image: url("../images/btn-icon-directions@2x.png");
                background-repeat: no-repeat;
                background-size: 35%;
                display: flex;
                height: 20px;
                width: 40px;
                position: absolute;
                right: -3px;
                bottom: 10px;

                @include respond-range(320px, 767px) {
                  right: 17px;
                }
              }
            }

            .contact {
              height: 44px;
              width: 196px;

              @include respond-range(320px, 767px) {
                margin-top: 20px;
                width: 254px;
              }

              @include respond-range(768px, 1024px) {
                margin-top: 20px;
              }

              @include respond-range(1025px, 1300px) {
                margin-top: 10px;
              }
            }

            .contact:hover {
            }
          }

          .header {
            font-weight: 900;
            color: #353b48;
            font-size: 38px;
            line-height: 48px;
            text-align: left;
          }

          .des {
            margin-top: 2%;

            @include respond-range(320px, 767px) {
              margin-top: 0;
              font-size: 17px;
              line-height: 20px;
            }
          }

          .head {
            margin-top: 10%;
            color: #353b48;

            @include respond-range(320px, 767px) {
              font-size: 26px;
              line-height: 34px;
              margin-top: 20px;
            }
          }

          .para {
            line-height: 20px;
            margin-top: 2%;

            @include respond-range(320px, 600px) {
              font-size: 16px;
            }
          }
        }
      }

      .headQuatersMainRightMap {
        width: 50%;
        padding: 20% 0 0 0;
        display: none;

        @include respond-range(320px, 767px) {
          width: 100%;
          padding: 25px 0 0 0;
          display: block;
        }

        .map-container {
          height: 300px;
          width: 100%;

          @include respond-range(320px, 767px) {
            width: 100%;
            margin-top: 5%;
          }
        }
      }

      .headQuatersMainRight {
        width: 50%;
        height: 100%;
        background-color: #353b48;
        padding: 8%;

        @include respond-range(320px, 767px) {
          width: 100%;
          padding: 10% 0;
        }

        @include respond-range(1024px, 1200px) {
          padding: 5%;
        }

        @include respond-range(768px, 1024px) {
          padding: 3%;
          width: 100%;
          padding: 10% 15%;
        }

        .headQuatersTalk {
          @include respond-range(320px, 600px) {
            width: 100%;
            margin: auto;
            padding: 0 32px;
          }

          @include respond-range(601px, 767px) {
            width: 60%;
            margin: auto;
          }

          .head {
            font-weight: 900;
            color: #ffffff;
            line-height: 48px;
          }

          .subHead {
            color: #fffdf5;
            line-height: 20px;
            margin-top: 3%;

            @include respond-range(320px, 767px) {
              margin-top: 0;
            }
          }
        }

        .headQuatersEnquiries {
          display: flex;
          width: 100%;

          @include respond-range(320px, 600px) {
            width: 100%;
            margin: auto;
            padding: 0 32px;
          }

          @include respond-range(601px, 767px) {
            width: 60%;
            margin: auto;
          }

          @include respond-range(320px, 650px) {
            flex-direction: column;
          }

          .headQuatersGeneral {
            width: 50%;
            margin-right: 15px;

            @include respond-range(320px, 650px) {
              width: 100%;
            }

            .enquiries {
              margin-top: 20%;
              line-height: 33px;
              color: #ffffff;

              @include respond-range(320px, 650px) {
                margin-top: 30px;
              }
            }

            .number {
              color: #cfcfcf;
              font-weight: 300;
              line-height: 26px;
            }
          }

          .headQuatersSupport {
            width: 50%;

            @include respond-range(320px, 650px) {
              width: 100%;
            }

            .enquiries {
              margin-top: 20%;
              line-height: 33px;
              color: #ffffff;

              @include respond-range(320px, 650px) {
                margin-top: 30px;
              }
            }

            .number {
              color: #cfcfcf;
              font-weight: 300;
              line-height: 26px;
            }
          }
        }

        .headQuatersSales {
          display: flex;
          width: 100%;

          @include respond-range(320px, 600px) {
            width: 100%;
            margin: auto;
            padding: 0 32px;
          }

          @include respond-range(601px, 767px) {
            width: 60%;
            margin: auto;
          }

          @include respond-range(320px, 650px) {
            flex-direction: column;
          }

          .headQuatersSale {
            width: 50%;

            @include respond-range(320px, 650px) {
              width: 100%;
            }

            .enquiries {
              margin-top: 20%;
              line-height: 33px;
              color: #ffffff;

              @include respond-range(320px, 650px) {
                margin-top: 30px;
              }
            }

            .number {
              color: #cfcfcf;
              font-weight: 300;
              line-height: 26px;
            }

            .headQuaters-funTxt {
              margin-top: 15%;
              color: #fafbfd;
              font-family: Montserrat;
              font-size: 18px;
              line-height: 29px;

              @include respond-range(320px, 650px) {
                margin-top: 20px;
              }
            }
          }

          .headQuatersCareers {
            width: 50%;

            @include respond-range(320px, 650px) {
              width: 100%;
            }

            .careers {
              margin-top: 20%;
              line-height: 33px;
              color: #ffffff;

              @include respond-range(320px, 650px) {
                margin-top: 30px;
              }
            }

            .number {
              color: #cfcfcf;
              font-weight: 300;
              line-height: 26px;
            }
          }
        }
      }
    }
  }

  .otherLocation-container {
    .otherLocation-main {
      display: none;
      width: 100%;
      max-width: 1400px;
      margin: auto;

      @include respond-range(768px, 1550px) {
        width: 78%;
      }

      @include respond-range(768px, 1024px) {
        width: 90%;
      }

      .text {
        @include respond-range(320px, 767px) {
          color: #353b48;
          font-size: 32px;
          line-height: 42px;
          padding: 0 32px;
          text-align: left !important;
        }
      }

      .otherLocation-InsideMain {
        display: flex;

        @include respond-range(320px, 767px) {
          align-items: center;
        }

        .otherLocation-leftSec {
          width: 100%;
          display: flex;
          margin-top: 6%;

          @include respond-range(320px, 767px) {
            align-items: center;
            width: 100%;
            flex-direction: column;
            margin-top: 0;
          }

          .otherLocationText {
            width: 50%;

            @include respond-range(320px, 767px) {
              text-align: left;
              padding: 0 32px;
              margin-bottom: 40px;
            }

            .otherLocationTexts {
              width: 100%;
              display: flex;

              @include respond-range(320px, 767px) {
                justify-content: center;
              }

              .otherTexts {
                display: flex;
                flex-direction: column;

                @include respond-range(320px, 767px) {
                  padding: 0 32px;
                }
              }
            }
          }

          .otherLocation-mapLaguna {
            height: 300px;
            width: 50%;

            @include respond-range(320px, 767px) {
              width: 100%;
              margin-top: 5%;
            }
          }

          .address {
            color: #353b48;
            font-weight: bold;
            line-height: 33px;
            margin-top: 10%;

            @include respond-range(320px, 767px) {
              margin-top: 5%;
              font-size: 26px;
              line-height: 34px;
            }
          }

          .subAddress {
            line-height: 25px;
            margin-top: 2%;
          }

          .number {
            line-height: 24px;
          }

          .headQuaters-buttonSec {
            display: flex;
            flex-direction: row;
            margin-top: 7%;

            @include respond-range(320px, 767px) {
              justify-content: center;
              flex-direction: column;
              margin-top: 10px;
            }

            @include respond-range(768px, 1024px) {
              flex-direction: column;
            }

            @include respond-range(1025px, 1300px) {
              flex-direction: column;
            }

            .WorkTogether-Sec {
              position: relative;
              margin-right: 10px;

              @include respond-range(320px, 767px) {
                margin-right: 0;
              }

              .contact {
                height: 44px;
                width: 196px;
                position: relative;

                @include respond-range(320px, 767px) {
                  margin-top: 20px;
                  width: 254px;
                }
              }

              .btn-Contact {
                width: 160px;
                text-align: left;
              }

              .icon_right {
                background-image: url("../images/btn-icon-directions@2x.png");
                background-repeat: no-repeat;
                background-size: 35%;
                display: flex;
                height: 20px;
                width: 40px;
                position: absolute;
                top: 13px;
                right: -3px;

                @include respond-range(320px, 767px) {
                  right: 17px;
                }
              }
            }

            .contact {
              height: 44px;
              width: 196px;

              @include respond-range(320px, 767px) {
                margin-top: 20px;
                width: 254px;
              }

              @include respond-range(768px, 1024px) {
                margin-top: 20px;
              }

              @include respond-range(1025px, 1300px) {
                margin-top: 10px;
              }
            }
          }
        }
      }

      .otherLocation-rightSec {
        width: 100%;
        display: flex;
        margin-top: 8%;

        @include respond-range(320px, 767px) {
          flex-direction: column;
        }

        .otherLocationTextMap {
          width: 50%;

          @include respond-range(320px, 767px) {
            padding: 0 32px;
            margin-bottom: 40px;
            width: 100%;
          }

          .otherLocationTexts {
            width: 100%;
            display: flex;

            @include respond-range(320px, 767px) {
              justify-content: center;
            }

            .otherTexts {
              display: flex;
              flex-direction: column;

              @include respond-range(320px, 767px) {
                padding: 0 32px;
              }
            }
          }
        }

        .otherLocation-mapLaguna {
          height: 300px;
          width: 50%;

          @include respond-range(320px, 767px) {
            width: 100%;
            margin: auto;
            margin-top: 5%;
          }
        }

        .address {
          color: #353b48;
          font-weight: bold;
          line-height: 33px;
          margin-top: 10%;

          @include respond-range(320px, 767px) {
            margin-top: 0%;
            font-size: 26px;
            line-height: 34px;
          }
        }

        .subAddress {
          line-height: 25px;
          margin-top: 2%;
        }

        .number {
          line-height: 24px;
        }

        .headQuaters-buttonSec {
          display: flex;
          flex-direction: row;
          margin-top: 7%;

          @include respond-range(320px, 767px) {
            justify-content: center;
            flex-direction: column;
            margin-top: 10px;
          }

          @include respond-range(768px, 1300px) {
            flex-direction: column;
          }

          @include respond-range(1025px, 1300px) {
            flex-direction: column;
          }

          .WorkTogether-Sec {
            position: relative;
            margin-right: 10px;

            @include respond-range(320px, 767px) {
              margin-right: 0;
            }

            .contact {
              height: 44px;
              width: 196px;
              position: relative;

              @include respond-range(320px, 767px) {
                width: 254px;
              }
            }

            .btn-Contact {
              width: 160px;
              text-align: left;
            }

            .icon_right {
              background-image: url("../images/btn-icon-directions@2x.png");
              background-repeat: no-repeat;
              background-size: 35%;
              display: flex;
              height: 20px;
              width: 40px;
              position: absolute;
              top: 13px;
              right: -3px;

              @include respond-range(320px, 767px) {
                right: 17px;
              }
            }
          }

          .contact {
            height: 44px;
            width: 196px;

            @include respond-range(320px, 767px) {
              margin-top: 20px;
              width: 254px;
            }

            @include respond-range(768px, 1024px) {
              margin-top: 20px;
            }

            @include respond-range(1025px, 1300px) {
              margin-top: 10px;
            }
          }
        }
      }

      .contactSeperator {
        display: flex;
        justify-content: center;

        .contact-plusSignSeparator {
          height: 56px;
          width: 476px;
          background-image: url("../images/bg-plus-2row11@2x.png");
          background-size: contain;
          margin-top: 12%;

          @include respond-range(320px, 460px) {
            background-size: cover;
            height: 33px;
            width: 70%;
            margin-top: 50px;
          }
        }
      }

      .text {
        @include respond-range(320px, 767px) {
          text-align: center;
        }
      }
    }
  }

  .aboutHiring-container {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;

    @include respond-range(320px, 767px) {
      margin-bottom: 60px;
      margin-top: 50px;
    }

    .aboutHiring-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 62%;
      margin: auto;
      height: 100%;

      @include respond-range(320px, 767px) {
        width: 100%;
        padding: 0 32px;
      }

      @include respond-range(1024px, 1200px) {
        max-width: 70%;
      }

      .aboutHiring-Title {
        color: #ff2255;
        font-family: Montserrat;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: -0.39px;
        line-height: 29px;
        text-align: center;
      }

      .aboutHiring-desc {
        color: #353b48;
        font-family: Muli;
        font-size: 38px;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        margin-top: 2%;
        width: 100%;

        @include respond-range(320px, 767px) {
          font-size: 28px;
        }

        @include respond-range(768px, 1024px) {
          font-size: 32px;
        }
      }

      .aboutHiring-ContactSec {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 1%;
        width: 50%;
        color: #cfcfcf;
        font-family: Montserrat;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        display: none !important;

        @include respond-range(320px, 767px) {
          width: 90%;
          display: none;
        }

        @include respond-range(768px, 1024px) {
          font-size: 17px;
        }

        @include respond-range(1024px, 1200px) {
          width: 70%;
          margin-top: 2%;
        }
      }

      .WorkTogether-ContactSec {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 5%;
        width: 100%;

        @include respond-range(320px, 600px) {
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        }

        .WorkTogether-seperator {
          margin: 0 4%;
          text-align: center;
          display: flex;
          align-items: center;
          color: #afafaf;
          font-family: Muli;
          font-size: 18px;
          font-weight: 600;
          line-height: 23px;

          @include respond-range(320px, 600px) {
            margin: 10px;
          }
        }

        .WorkTogether-Sec {
          position: relative;

          .contact {
            height: 44px;
          }

          .btn-Contact {
            width: 75%;
            text-align: left;
          }

          .icon_right {
            background-image: url("../images/btn-icon-arrowRight@2x.png");
            background-repeat: no-repeat;
            background-size: 35%;
            display: flex;
            height: 20px;
            width: 40px;
            position: absolute;
            top: 16px;
            right: -3px;
          }
        }
      }
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbbbbb;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbbbbb;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbbbbb;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}
