@import "../modules/_mixins.scss";
@import "../modules/_constants.scss";

.content-container {
	width: 70%;
	margin: 0 auto;
	margin-top: 150px;
	margin-bottom: 150px;
	max-width: 1120px;

	@include respond-max($breakpoint-sm) {
		width: 85%;
	}


	li {
		color: #A6B1B9;

		p {
			margin-bottom: 0;
		}

		p:first-child {
			margin-top: 0;
			margin-bottom: 0;
		}

		p:last-child {
			margin-bottom: 1em;
		}
	}
	
	.privacy-bottom-margin {
		padding-bottom: 150px;
	}
}