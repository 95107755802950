﻿//import constants/mixins
@import "../modules/_constants.scss";
@import "../modules/_mixins.scss";

//import fonts
@import url('https://fonts.googleapis.com/css?family=Muli:300,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');


/* -------------------- */
/* FONT STYLES */
/* --------------------*/

/* Headers */
.header-1, .header-2, .header-3, .header-4, .header-5 {
    font-family: Muli, sans-serif;
    line-height: 1em;
}

.header-1 {
    font-size: 56px;

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
        font-size: 40px;
    }

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        font-size: 27px;
    }

}

.header-2 {
    font-size: 44px;

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
        font-size: 35px;
    }

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        font-size: 24px;
    }

}

.header-3 {
    font-size: 38px;

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
        font-size: 32px;
    }
    
    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        font-size: 20px;
    }

}

.header-4 {
    font-size: 32px;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        font-size: 20px;
    }
}

.header-5 {
    font-size: 26px;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        font-size: 20px;
    }
}

p {
	color: $light-gray;
	font-size: 18px;
	line-height: 26px !important;
}
/* paragraphs */
.paragraph, .paragraph-small, .paragraph-smallest {
    font-family: Montserrat, sans-serif;
}

.paragraph {
    font-family: Montserrat, sans-serif;
    font-size: 17px;
    line-height: 29px;
}

.paragraph-small {
    font-size: 15px;
    line-height: 19px;
}

.paragraph-smallest {
    font-size: 12px;
    line-height: 15px;
}


/* fw stands for font-weight */
.fw-light {
    font-weight: 300;
}

.fw-medium {
    font-weight: 500;
}

.fw-semi-bold {
    font-weight: 600;
}

.fw-bold {
    font-weight: 700;
}

.fw-extra-bold {
    font-weight: 800;
}

.fw-boldest {
    font-weight: 900;
}

/*-----------------------------*/



/* ----------------------- */
/* BUTTON STYLES */

.btn-xxlarge, .btn-xlarge, .btn-large, .btn-medium, .btn-small {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 22px;
    font-family: Montserrat, sans-serif;
    line-height: 19px;
    font-weight: 500;
    padding: 11px 0;
    outline: none;
    cursor: pointer;
}

.btn-xxlarge {
    width: 245px
}

.btn-xlarge {
    width: 203px;
}

.btn-large {
    width: 140px;
}

.btn-medium {
    width: 122px;
}

.btn-small {
    width: 122px;
    font-size: 9px;    
    padding: 4px;
    font-weight: 700;
}

.btn-grey {
    background: white;
}

.btn-pink {
    background-color: $hot-pink;
    color: #FFFFFF;
}

.btn-pink:hover {
    background-color: #F40C41;
    color: #FFFFFF;
}

.btn-white {
    background-color: #FFFFFF;
    color: $hot-pink;
    border: 1px solid $hot-pink;
}
.btn-white:hover{
    // background-color: #F40C41;        
    color: $hot-pink;
}

.btn-green {
    background-color: $lime-green;
    color: #FFFFFF;
}

.btn-grey {
    border: 1px solid #999999;
    color: #999999;
}


a:hover {
    color: inherit;
}

.cookie-btn {
    color: white !important;
    background-color: $hot-pink !important;
    border-radius: 20px !important;
    padding: 10px 20px !important;
    margin-right: 40px !important;
    position: relative;
    @include respond-min(1400px) {
        top: 5px; 
    }
    @include respond-max(1160px) {
        top: 5px; 
    }
    @include respond-max(750px) {
        top: 15px; 
    }
    @include respond-max(500px) {
        margin: 0 auto;
        top: 0px;
        margin-left: 40vw !important;
        
    }
}

.cookie-content {
   
    margin-left: 40px !important;
    @include respond-max(500px) {
        margin-left: 15px !important;
    }
}

.cookie-container {
    opacity: 0.9;

    @include respond-min(1400px) {
        .cookie-content {
            margin-left: 20vw !important;
        }
        .cookie-btn {
            margin-right: 20vw !important;
        }
    }
    @include respond-min(2000px) {
        .cookie-content {
            margin-left: 30vw !important;
        }
        .cookie-btn {
            margin-right: 30vw !important;
        }
    }
}

