﻿@import "../modules/constants";
@import "../modules/mixins";

.footer-container {
  height: 550px;

  .footer-content-container {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: flex;
    justify-content: center;
    .footer-contentMain-container {
      width: 100%;
      height: 3px;
      background-color: #f5f5f5;

      .footer-text-background-container {
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        z-index: 0;
        margin-top: 10%;
        @include respond-range(320px, 767px) {
          margin-bottom: 10%;
        }

        .footer-text-content {
          width: 78%;
          display: flex;
          position: relative;
          max-width: 1400px;
          @include respond-range(320px, 767px) {
            width: 100%;
            padding: 0 16px;
            flex-direction: column;
          }
          @include respond-range(768px, 1024px) {
            width: 90%;
          }

          .footer-text-contentLeft {
            width: 50%;
            @include respond-range(320px, 767px) {
              width: 100%;
            }

            .footer-Fin-icon {
              width: 107px;
              z-index: 2;
              height: 61px;
              background-image: url("../images/logo-FIN-TITLE-1-Dark-Blue@2x.png");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: 39%;
              background-position-y: top;
              @include respond-range(320px, 767px) {
                width: 75px;
                height: 55px;
                background-size: contain;
              }
            }
            .footer-Fin-Text {
              color: #353b48;
              font-family: Muli;
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              width: 58%;
              margin-top: 5px;
              @include respond-range(320px, 767px) {
                width: 100%;
              }
            }
            .footer-inputText {
              margin-top: 7%;
              display: none;

              .footer-placeHolder {
                height: 44px;
                width: 60%;
                font-family: Montserrat;
                font-size: 14px;
                line-height: 18px;
                border: 1px solid #ebebeb;
                border-radius: 3px 0 0 3px;
                background-color: #ffffff;
                padding-left: 2%;
                @include respond-range(320px, 767px) {
                  width: 90%;
                }
                @include respond-range(768px, 1024px) {
                  width: 75%;
                }
                @include respond-range(1024px, 1200px) {
                  font-size: 11px;
                }
              }
              .footer-emailSent {
                width: 44px;
                height: 44px;
                border: 1px solid #ff2255;
                border-radius: 0 3px 3px 0;
                background-color: #ff2255;
                position: relative;
                cursor: pointer;
              }
              .footer-emailSentIcon {
                z-index: 2;
                background-image: url("../images/btn-icon-arrowRight@2x.png");
                background-repeat: no-repeat;
                background-size: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                height: 100%;
                width: 100%;
                top: 33%;
                left: 30%;
              }
            }
            .footer-allRightsWeb {
              margin-top: 30%;
              color: #afafaf;
              font-family: Montserrat;
              font-size: 12px;
              line-height: 15px;
              @include respond-range(320px, 767px) {
                display: none;
              }

              .footer-textPolicy {
                cursor: pointer;
                text-decoration: underline;
                color: #afafaf;
                margin-left: 2%;
                font-size: 12px;
              }
              .Privacy {
                margin-left: 3%;
                margin-right: 0%;
              }
            }
          }

          .footer-page-section {
            width: 50%;
            @include respond-range(320px, 767px) {
              width: 100%;
            }

            .footer-section {
              float: right;
              width: 100%;
              z-index: 2;
              height: 48%;
              margin-top: 60px;
              display: flex;
              justify-content: space-between;
              @include respond-range(320px, 767px) {
                margin-top: 5%;
              }
              @include respond-range(768px, 1024px) {
                margin-top: 60px;
              }
              @include respond-range(1025px, 1439px) {
                width: 100%;
              }

              .footer-section-left {
                .footer-pageNav {
                  color: #353b48;
                  font-family: Montserrat;
                  font-size: 15px;
                  height: 33px;
                  line-height: 33px;
                  font-weight: 500;
                  cursor: pointer;
                  @include respond-range(320px, 359px) {
                    font-size: 12px;
                  }
                  @include respond-range(360px, 767px) {
                    font-size: 14px;
                  }
                }
              }

              .footer-section-right {
                position: relative;

                .footer-pageNav {
                  color: #353b48;
                  font-family: Montserrat;
                  font-size: 15px;
                  height: 33px;
                  line-height: 33px;
                  font-weight: 500;
                  cursor: pointer;
                  text-align: right;
                  @include respond-range(320px, 767px) {
                    font-size: 12px;
                  }
                  @include respond-range(360px, 767px) {
                    font-size: 14px;
                  }
                }

                .socialLogos {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  .footer-instagramLogo {
                    height: 16px;
                    width: 16px;
                    background-image: url("../images/icon-instagram@2x.png");
                    background-repeat: no-repeat;
                    background-size: 100%;
                    margin-right: 6%;
                  }

                  .fbLogo {
                    background-image: url("../images/icon-facebook@2x.png");
                  }
                  .twitterLogo {
                    background-image: url("../images/icon-LinkedIn@2x.png");
                    margin-right: 0%;
                  }
                  .insta {
                    display: none;
                  }
                }
              }
            }
          }

          .footer-bottomToTop {
            width: 45px;
            height: 45px;
            position: absolute;
            border-radius: 50%;
            background-color: #ff2255;
            bottom: 0;
            right: 0;
            padding-left: 16px;
            padding-top: 13px;
            cursor: pointer;
            @include respond-range(320px, 767px) {
              bottom: -20%;
              right: 16px;
            }

            .footer-bottomToTopIcon {
              width: 45px;
              height: 45px;
              background-image: url("../images/btn-icon-arrowUp@2x.png");
              background-repeat: no-repeat;
              background-size: 30%;
            }
          }
          .footer-bottomToTop:hover {
            background-color: #f40c41;
          }
          .footer-allRightsMob {
            margin-top: 5%;
            color: #afafaf;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 21px;
            text-align: center;
            @include respond-range(768px, 3000px) {
              display: none;
            }
          }
          .footer_rights {
            display: flex;
            justify-content: center;
            margin-top: 12px;
            @include respond-range(768px, 3000px) {
              display: none;
            }

            .footer-textPolicy {
              cursor: pointer;
              text-decoration: underline;
              color: #afafaf;
              font-size: 12px;
            }
            .Privacy {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbbbbb;
}

::-ms-input-placeholder {
  color: #bbbbbb;
}

input:focus {
  outline: none;
}
