@import "../modules/constants";
@import "../modules/mixins";

.aboutPage-Container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 5%;

  .navbar-hero-container {
    background: #353b48;

    .aboutPage-hero-container {
      background-image: url("../images/about-photo-hero@2x.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      height: 120vw;
      width: 100%;
      max-height: 1300px;

      @include respond-range(320px, 351px) {
        position: relative;
        max-height: 760px;
        height: 240vw;
      }

      @include respond-range(352px, 403px) {
        position: relative;
        max-height: 710px;
        height: 230vw;
      }

      @include respond-range(404px, 600px) {
        position: relative;
        max-height: 620px;
        height: 230vw;
      }

      @include respond-range(601px, 767px) {
        position: relative;
        max-height: 730px;
        height: 200vw;
      }

      @include respond-range(768px, 1024px) {
        height: 75vw;
        max-height: 600px;
        background-size: cover;
      }

      @include respond-range(1820px, 3000px) {
        background-size: cover;
      }

      .hero-content {
        @include respond-range(320px, 600px) {
          position: absolute;
          width: 100%;
          bottom: 70%;
        }

        @include respond-range(601px, 767px) {
          position: absolute;
          width: 100%;
          bottom: 60%;
        }

        @include respond-range(1024px, 1200px) {
          height: 62vw;
        }

        @include respond-range(1201px, 2000px) {
          height: 64vw;
        }

        .hero-content-container {
          width: 100%;
          margin-top: 0;
          display: flex;
          justify-content: center;

          .hero-text-content {
            width: 100%;

            .hero-text-contentSec {
              display: flex;
              justify-content: center;
              width: 100%;
              position: absolute;
              z-index: 0;

              @include respond-range(320px, 767px) {
                width: 100%;
              }

              .hero-text-contentSecLeft {
                width: 78%;
                margin-top: 12%;
                max-width: 1400px;

                @include respond-range(320px, 767px) {
                  width: 100%;
                  padding: 0 32px;
                }

                @include respond-range(768px, 1024px) {
                  width: 90%;
                }

                .hero-title {
                  color: #fdf7f5;
                  font-family: Montserrat;
                  font-size: 32px;
                  font-weight: 600;
                  letter-spacing: -0.48px;
                  line-height: 29px;

                  @include respond-range(320px, 1024px) {
                    font-size: 18px;
                    line-height: 29px;
                  }
                }

                .hero-title-transaction {
                  color: #ffffff;
                  font-family: Muli;
                  font-size: 56px;
                  font-weight: 900;
                  line-height: 65px;
                  margin-top: 2%;

                  @include respond-range(320px, 767px) {
                    font-size: 34px;
                    line-height: 38px;
                    margin-top: 0;
                  }

                  @include respond-range(768px, 1024px) {
                    font-size: 34px;
                    line-height: 43px;
                    margin-top: 0;
                  }
                }

                .hero-paragraph {
                  color: #ffffff;
                  font-family: Muli;
                  font-size: 56px;
                  font-weight: 300;
                  line-height: 65px;
                  max-width: 70%;

                  @include respond-range(320px, 767px) {
                    font-size: 34px;
                    line-height: 38px;
                    max-width: 100%;
                  }

                  @include respond-range(768px, 1024px) {
                    font-size: 34px;
                    line-height: 43px;
                    max-width: 100%;
                  }
                }

                .about-heroPlusSign {
                  background-image: url("../images/bg-plus-row6@2x.png");
                  background-repeat: repeat;
                  background-size: contain;
                  height: 14px;
                  width: 254px;
                  margin-top: 10%;

                  @include respond-range(320px, 767px) {
                    margin-top: 30px;
                  }

                  @include respond-range(768px, 1024px) {
                    margin-top: 7%;
                  }
                }

                .about-FinTechDesc {
                  color: #fafbfd;
                  font-family: Montserrat;
                  font-size: 36px;
                  line-height: 69px;
                  width: 46%;
                  margin-top: 10%;

                  @include respond-range(320px, 767px) {
                    font-size: 17px;
                    line-height: 29px;
                    margin-top: 30px;
                    width: 100%;
                  }

                  @include respond-range(768px, 1024px) {
                    font-size: 18px;
                    line-height: 29px;
                    width: 76%;
                    margin-top: 7%;
                  }

                  @include respond-range(1025px, 1200px) {
                    font-size: 36px;
                    width: 60%;
                  }
                }
              }
            }
          }
        }
      }

      .about-heroBottomSec {
        height: 328px;
        width: 100%;
        background-color: #353b48;
      }
    }
  }

  .whyWork-section {
    @include respond-range(320px, 767px) {
      height: auto;
      margin-top: 10%;
    }

    @include respond-range(768px, 1024px) {
      margin-top: 0%;
      margin-bottom: 13%;
    }

    .whyWork-section-mainContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      .whyWork-sectionOver {
        width: 78%;
        display: flex;
        max-width: 1400px;
        margin: auto;

        @include respond-range(320px, 767px) {
          flex-direction: column;
          width: 100%;
          margin: auto;
        }

        @include respond-range(768px, 1024px) {
          width: 90%;
        }

        .whyWork-text-background-container {
          @include respond-range(320px, 767px) {
            width: 100%;
            height: auto;
          }

          width: 120%;
          height: 58vw;
          float: right;

          .whyWork-text-contentLeft {
            float: left;
            width: 130%;
            position: relative;
            z-index: 2;
            height: 100%;
            top: 11%;
            margin-top: 10%;
            right: 0%;

            @include respond-range(320px, 767px) {
              width: 100%;
              padding: 0 32px;
              top: 0;
              right: 0;
              margin: auto;
              margin-top: 10px;
            }

            @include respond-range(768px, 890px) {
              top: 0%;
            }

            @include respond-range(891px, 1024px) {
              top: 2% !important;
            }

            .whyWork-lighter-header {
              color: #452a49;
              font-family: Muli;
              font-size: 44px;
              font-weight: 900;
              line-height: 46px;

              @include respond-range(320px, 1024px) {
                font-size: 32px;
                line-height: 42px;
              }
            }

            .txt-inner {
              margin-top: 4%;
              color: #000000;
              background: #fff;
              padding-right: 120px;

              @include respond-range(320px, 767px) {
                font-size: 16px;
                line-height: 27px;
                padding-right: 0px;
                margin-top: 15px;
              }

              @include respond-range(768px, 1024px) {
                width: 154%;
                padding-right: 0px;
                font-size: 16px;
                line-height: 27px;
              }
            }

            .about-finChange {
              color: #000000;
              background: #fff;
              padding-top: 5%;
              padding-bottom: 9%;

              @include respond-range(320px, 1024px) {
                font-size: 20px;
                line-height: 32px;
                padding-bottom: 5%;
              }
            }
          }

          .whyWork-text-contentRight {
            float: left;
            width: 150%;
            position: relative;
            height: 100%;
            top: 11%;
            margin-top: 10%;
            left: 23%;

            @include respond-range(320px, 767px) {
              left: -32%;
              top: 0;
              margin-top: 0%;
              height: 290px;
            }

            .whyWork-ImgContainer {
              background-image: url("../images/about-photo-workWithUs@2x.jpg");
              background-repeat: no-repeat;
              background-size: 100%;
              height: 60%;
              width: 120%;

              @include respond-range(320px, 767px) {
                height: 100%;
                width: 130%;
              }

              @include respond-range(768px, 1024px) {
                height: 70%;
              }
            }
          }

          .imgSec {
            left: -80%;
            top: 36%;

            @include respond-range(320px, 767px) {
              top: 0;
              max-height: 400px;
            }

            @include respond-range(768px, 1024px) {
              top: 40%;
            }

            @include respond-range(1025px, 1200px) {
              top: 57%;
            }
          }
        }
      }
    }
  }

  .whyWork-bottomTextSec {
    width: 100%;
    margin: auto;
    max-width: 1400px;
    margin-top: 3%;

    @include respond-range(320px, 767px) {
      width: 100%;
      padding: 0 32px;
    }

    @include respond-range(768px, 1024px) {
      width: 90%;
    }

    @include respond-range(1025px, 1550px) {
      width: 78%;
    }

    @include respond-range(1024px, 1200px) {
      margin-top: 20%;
    }

    .txtChange {
      color: #000000;
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
    }

    .txt-innerP {
      color: #000000;
      padding-top: 20px;
    }
  }

  .mission-sectionContainer {
    width: 100%;
    padding: 0 20px;
    margin-top: 10%;
    height: 500px;

    @include respond-range(320px, 600px) {
      height: 420px;
      padding: 0;
      margin-top: 50px;
    }

    @include respond-range(601px, 767px) {
      height: 350px;
      padding: 0;
      margin-top: 50px;
    }

    @include respond-range(768px, 1024px) {
      height: 420px;
      margin-bottom: 5%;
    }

    @include respond-range(1024px, 1200px) {
      height: 435px;
    }

    .mission-plusIcon {
      background-image: url("../images/background-tile@2x.png");
      background-size: 50px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      .mission-statement {
        display: flex;

        .missionTxtDes {
          line-height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56%;
          margin: auto;
          padding: 40px 80px;
          background: #ffffff;

          @include respond-range(320px, 767px) {
            width: 100%;
            padding: 10px 32px;
            text-align: left;
            font-size: 24px;
            line-height: 38px;
          }

          @include respond-range(768px, 1024px) {
            padding: 40px;
            width: 64%;
            font-size: 26px;
            line-height: 38px;
          }

          @include respond-range(1024px, 1200px) {
            width: 76%;
            padding: 5px 80px;
          }
        }
      }
    }
  }

  .paragraph-linkSec {
    display: flex;
    justify-content: center;
    height: 12vw;
    width: 100%;
    padding-top: 2%;

    @include respond-range(320px, 767px) {
      padding-top: 20px;
    }

    @include respond-range(768px, 1024px) {
      padding-bottom: 50px;
      padding-top: 0%;
    }

    @include respond-range(1366px, 1500px) {
      height: 15vw;
      padding-top: 3%;
    }

    .paragraph-end-link {
      color: #ff2255;
      font-family: Montserrat;
      font-size: 17px;
      line-height: 29px;
      text-decoration: underline;
      font-weight: 500;
    }

    .paragraph-end-link:hover {
      color: #f40c41;
    }
  }

  .value-serviceContainer {
    height: 45vw;
    background-color: #fcfcfc;

    @include respond-range(320px, 767px) {
      height: auto;
      margin-top: 60px;
      padding-bottom: 50px;
    }

    @include respond-range(768px, 865px) {
      height: 155vw;
      max-height: 1025px;
    }

    @include respond-range(866px, 1024px) {
      height: 125vw;
      max-height: 840px;
    }

    @include respond-range(1025px, 3000px) {
      height: 105vw;
      max-height: 960px;
    }

    .value-experience-container {
      width: 100%;
      height: 49vw;
      margin-top: 0;
      display: flex;
      justify-content: center;

      @include respond-range(320px, 767px) {
        height: auto;
      }

      .value-content-container {
        width: 100%;

        @include respond-range(320px, 767px) {
          display: flex;
          flex-direction: column;
        }

        .value-text-background-container {
          display: flex;
          justify-content: center;
          width: 50%;
          z-index: 0;
          height: 60vw;
          position: relative;
          top: 140px;
          left: -3%;

          @include respond-range(320px, 767px) {
            width: 100%;
            height: 85%;
            top: 0;
            left: 0;
          }

          @include respond-range(768px, 1024px) {
            top: 13%;
          }

          .value-text-content {
            width: 70%;

            @include respond-range(320px, 767px) {
              width: 100%;
              padding: 0 32px;
            }

            .value-brighter-header {
              color: #452a49;
              font-family: Muli;
              font-size: 38px;
              font-weight: 900;
              line-height: 46px;

              @include respond-range(320px, 1024px) {
                font-size: 32px;
                line-height: 38px;
              }
            }

            .paragraphText {
              color: #afafaf;
              margin-top: 15%;

              @include respond-range(320px, 1024px) {
                font-size: 16px;
                line-height: 27px;
                margin-top: 40px;
              }

              .semiText {
                color: #34485e;
                padding-bottom: 4%;

                @include respond-range(320px, 1024px) {
                  font-size: 22px;
                  line-height: 28px;
                  padding-bottom: 15px;
                }
              }
            }

            .innovation {
              margin-top: 10%;

              @include respond-range(320px, 767px) {
                margin-top: 40px;
              }
            }

            .service {
              margin-top: 10%;

              @include respond-range(320px, 767px) {
                margin-top: 30px;
              }
            }
          }
        }

        .value-transition-container {
          height: 100%;
          width: 50%;
          float: left;

          @include respond-range(320px, 400px) {
            height: 600px;
            width: 100%;
            max-height: 257px;
          }

          @include respond-range(401px, 499px) {
            height: 600px;
            width: 100%;
            max-height: 320px;
          }

          @include respond-range(500px, 600px) {
            height: 600px;
            width: 100%;
            max-height: 400px;
          }

          @include respond-range(601px, 767px) {
            height: 600px;
            width: 100%;
            max-height: 500px;
          }

          .value-container-column-left {
            float: left;
            background-color: #fff;
            width: 100%;
            position: relative;
            z-index: 2;
            height: 73%;
            top: 150px;
            background-image: url("../images/about-photo-values@2x.jpg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 100%;
            background-position-y: top;

            @include respond-range(320px, 500px) {
              top: 0;
              background-size: 100%;
              height: 90%;
            }

            @include respond-range(501px, 767px) {
              top: 0;
              background-size: 100%;
              height: 90%;
            }

            @include respond-range(768px, 1024px) {
              top: 15%;
            }
          }
        }
      }
    }
  }

  .team-mainContainer {
    .team-txtContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 10%;
      width: 45%;
      margin: auto;
      max-width: 1400px;

      @include respond-range(320px, 767px) {
        width: 100%;
        padding: 0 32px;
        margin-top: 50px;
      }

      @include respond-range(768px, 1024px) {
        width: 90%;
      }

      @include respond-range(1025px, 1550px) {
        width: 58%;
      }

      .team-header {
        font-weight: 900;
        color: #452a49;

        @include respond-range(320px, 767px) {
          font-size: 32px;
          line-height: 38px;
          text-align: left;
          width: 100%;
        }
      }

      .teamTxt {
        color: #afafaf;
        text-align: center;
        margin-top: 3%;

        @include respond-range(320px, 767px) {
          font-size: 16px;
          line-height: 27px;
          text-align: left;
          margin-top: 15px;
        }
      }
    }

    .team-nameImgSectionWeb {
      width: 100%;
      max-width: 1400px;
      margin: auto;
      padding: 7% 32px;
      display: none;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;

      @include respond-min(1024px) {
        display: grid;
      }

      .team-imgName {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .team-nameImgSectionMob {
      width: 100%;
      max-width: 1400px;
      justify-content: space-between;
      display: flex;
      margin: auto;
      padding: 7% 32px;

      @include respond-min(1024px) {
        display: none !important;
      }

      .slider-container {
        width: 100%;
        height: auto;

        ul {
          position: relative;

          li {
            margin: 5px;
            height: 10px;
            border-radius: 8px;
          }

          button {
            width: 5px;
            height: 100%;
            border-radius: 8px;
            background-color: #e2e2e2;
          }

          button:before {
            content: none;
          }

          .slick-active {
            background-color: #e2e2e2;

            button {
              background-color: #ff2255;
              width: 100%;
              transition: width 3000ms ease-in-out;
            }
          }
        }
      }

      .team-imgName {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .team-img {
      width: 250px;
      height: 250px;
      object-fit: cover;

      @include respond-min($breakpoint-xsm) {
        width: 300px;
        height: 300px;
      }
    }

    .team-name {
      color: #34406b;
      font-family: Muli;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
      margin-top: 4%;

      @include respond-min($breakpoint-xsm) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .team-title {
      color: #999999;
      font-family: Montserrat;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-top: 2%;

      @include respond-min($breakpoint-xsm) {
        font-size: 18px;
        line-height: 26px;
      }
    }

    .teamPlus {
      width: 42%;
      max-width: 330px;
      margin: auto;

      @include respond-range(320px, 767px) {
        width: 60%;
        max-width: 60%;
      }

      .team-plusSec {
        height: 50px;
        width: 100%;
        margin-left: 4%;
        background-image: url("../images/background-tile@2x.png");
        background-size: contain;
        margin-top: 40px;

        @include respond-range(320px, 767px) {
          height: 40px;
          margin-top: 30px;
          margin-left: 5%;
        }
      }
    }

    .linked-link {
      display: block;
      width: 30px;
      margin: 0 auto;
      margin-top: 15px;

      .linked-in-icon {
        width: 100%;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url("../images/linkedin-icon@2x.png");
      }
    }
  }
  /*.aboutHiring-container {
        width: 100%;
        margin-top: 6%;
        margin-bottom: 10%;

        @include respond-range(768px, 1024px) {
            margin-top: 5%;
            margin-bottom: 10%;
        }


        .aboutHiring-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 1400px;
            margin: auto;
            height: 100%;

            @include respond-range(320px, 767px) {
                padding: 0 32px;
            }


            .aboutHiring-Title {
                color: #FF2255;
                font-family: Montserrat;
                font-size: 26px;
                font-weight: 600;
                letter-spacing: -0.39px;
                line-height: 29px;
                text-align: center;
            }

            .aboutHiring-desc {
                color: #353B48;
                font-family: Muli;
                font-size: 38px;
                font-weight: 600;
                line-height: 46px;
                text-align: center;
                margin-top: 2%;
                width: 70%;

                @include respond-range(320px, 767px) {
                    font-size: 28px;
                    line-height: 46px;
                    width: 100%;
                }

                @include respond-range(768px, 1024px) {
                    font-size: 32px;
                    width: 75%;
                }
            }

            .aboutHiring-ContactSec {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 4%;
                width: 100%;

                @include respond-range(320px, 600px) {
                    flex-direction: column;
                    align-items: center;
                    padding-top: 5%;
                }


                .aboutHiring-seperator {
                    margin: 0 4%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    color: #AFAFAF;
                    font-family: Muli;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 23px;

                    @include respond-range(320px, 600px) {
                        margin: 3% 0;
                    }
                }

                .aboutHiring-Sec {
                    position: relative;

                    .contact {
                        height: 44px;
                    }

                    .btn-Contact {
                        width: 75%;
                        text-align: left;
                    }

                    .icon_right {
                        background-image: url("../images/btn-icon-arrowRight@2x.png");
                        background-repeat: no-repeat;
                        background-size: 35%;
                        display: flex;
                        height: 20px;
                        width: 40px;
                        position: absolute;
                        top: 16px;
                        right: -3px;
                    }
                }
            }
        }
    }*/
  .news-sectionContainer {
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #fdf7f5;
    display: flex;

    @include respond-range(320px, 767px) {
      padding: 40px 0;
      /*margin-bottom: 50px;*/
    }

    .news-textSection {
      width: 100%;
      max-width: 1400px;
      margin: auto;
      display: flex;
      flex-direction: column;

      @include respond-range(320px, 767px) {
        width: 100%;
        padding: 0 32px;
      }

      @include respond-range(768px, 1024px) {
        width: 90%;
      }

      @include respond-range(1025px, 1550px) {
        width: 78%;
      }

      .newsDateText {
        display: flex;
        justify-content: space-between;
        margin-top: 4%;

        @include respond-range(320px, 767px) {
          margin-top: 40px;
        }

        .newsDate {
          color: #999999;
          font-family: Muli;
          font-size: 26px;
          line-height: 33px;
          text-align: right;

          @include respond-range(320px, 767px) {
            display: none;
          }
        }

        .newsText {
          @include respond-range(320px, 767px) {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }

      .newsHeader {
        color: #452a49;
        font-weight: 900;
        line-height: 46px;

        @include respond-range(320px, 767px) {
          font-size: 32px;
          line-height: 38px;
        }
      }

      .newsRead {
        display: flex;
        font-weight: 500;
        margin-top: 10px;
        margin-right: 3px;

        @include respond-range(320px, 767px) {
          flex-direction: column;
        }

        .newsTitleReport {
          color: #798fb9;
          text-decoration: underline;
        }

        .title {
          margin-left: 3px;

          @include respond-range(320px, 767px) {
            margin-left: 0;
          }
        }

        .newsSeperator {
          height: 15px;
          width: 2px;
          background-color: #999999;
          font-family: Montserrat;
          font-size: 17px;
          line-height: 20px;
          margin: 7px 20px 0 20px;

          @include respond-range(320px, 767px) {
            display: none;
          }
        }
      }
      /*.aboutHiring-Sec {
                position: relative;
                width: 203px;
                margin-top: 7%;

                @include respond-range(320px, 767px) {
                    display: none;
                }

                .contact {
                    height: 44px;
                }

                .btn-Contact {
                    width: 75%;
                    text-align: left;
                }

                .icon_right {
                    background-image: url("../images/btn-icon-arrowRight@2x.png");
                    background-repeat: no-repeat;
                    background-size: 35%;
                    display: flex;
                    height: 20px;
                    width: 40px;
                    position: absolute;
                    top: 16px;
                    right: -3px;
                }
            }*/
    }
  }

  .offices-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 3em;
    margin-top: 50px;
  }

  .office-name {
    margin-bottom: 15px;
  }

  .office-card-links {
    display: flex;
    margin-top: 30px;
  }

  .office-card-link {
    color: $hot-pink;
  }

  .office-card-link_margin {
    margin-right: 20px;
  }

  .slider-container.autoPlayOff {
    .slick-active {
      background-color: #ffffff !important;

      button {
        width: 50% !important;
        transition: none !important;
      }
    }
  }

  .slick-dots li button:before {
    font-size: 12px !important;
    color: #452a49 !important;
  }

  .slick-initialized .slick-slide {
    // max-height: 385px;
    min-height: 375px !important;

    @include respond-range(601px, 767px) {
      // max-height: 400px;
    }
  }
}

.jobsContainer {
  width: 100%;
  padding: 65px 0;
  background-color: #f8f8f8;

  .jobsContainer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1400px;
    margin: auto;
    height: 100%;
    width: 90%;
    position: relative;

    @include respond-max(767px) {
      width: 100%;
      padding: 0 32px;
    }

    @include respond-max(420px) {
      padding: 0 16px;
    }

    .jobsContainer-title {
      color: #ff2255;
      font-family: Montserrat;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: -0.39px;
      line-height: 29px;
      text-align: center;
    }

    .jobsContainer-desc {
      color: #353b48;
      font-family: Muli;
      font-size: 38px;
      font-weight: 600;
      line-height: 46px;
      text-align: center;
      margin-top: 2%;
      margin-bottom: 100px;
      width: 70%;

      @include respond-range(320px, 767px) {
        font-size: 28px;
        line-height: 46px;
        width: 100%;
      }

      @include respond-range(768px, 1024px) {
        font-size: 32px;
        width: 75%;
      }
    }
  }

  .jobsContainer-openPositions {
    height: 32px;
    width: 100%;
    color: #34485e;
    font-family: Montserrat;
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    margin-top: 10px;

    @include respond-max(330px) {
      font-size: 24px;
    }

    .icon_right {
      background-image: url("../images/btn-icon-arrowRight@2x.png");
      background-repeat: no-repeat;
      background-size: 35%;
      display: flex;
      height: 20px;
      width: 40px;
      position: absolute;
      top: 16px;
      right: -3px;
    }
  }

  .job-description-container {
    margin-top: 50px;
    margin-bottom: 50px;

    .job-header {
      color: #34485e;
      font-size: 32px;
    }

    .list-header {
      font-size: 24px;
      color: #353b48;
    }

    p {
      font-family: Montserrat;
      color: #787878;
      font-weight: 100;
    }

    ul {
      font-family: Montserrat;
      padding-left: 20px;

      li {
        margin-bottom: 12px;
        color: #152935;
        font-size: 15px;
      }
    }
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon_right {
      background-image: url("../images/btn-icon-arrowRight@2x.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      margin-left: 30px;
    }
  }

  .selector-container {
    width: 100%;
    border-bottom: 1px solid #d4d4d4;
    margin: 25px 0;
    font-family: Montserrat;

    .job-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 32px;
      cursor: pointer;

      .title {
        color: #152935;
        /*font-family: Montserrat;*/
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid $hot-pink;
      }

      .dropdown-arrow {
        background-image: url("../images/icon-down-arrow@2x (002).png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 12px;
        width: 12px;
        margin-left: 25px;
      }
    }

    .job-row {
      min-width: 200px;
    }
  }
}

.popup-selector {
  transform: translate(-1px, -53.5px) !important;
  padding-bottom: 0 !important;
  font-family: Montserrat;

  .popup-content {
    min-width: 300px;

    .top-row,
    .job-row {
      display: flex;
      cursor: pointer;
      border-bottom: 1px solid #d4d4d4;
    }

    .top-row {
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.833em;

      .up-arrow {
        background-image: url("../images/icon-up-arrow@2x (002).png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 12px;
        width: 12px;
      }
    }

    .popup-text {
      color: #152935;
      /*font-family: Montserrat;*/
      font-size: 14px;
      font-weight: 600;
    }

    .location-text {
      color: lightslategrey;
      /*font-family: Montserrat;*/
      font-size: 12px;
      font-weight: 100;
    }

    .active-pink {
      color: $hot-pink;
    }

    .job-row {
      flex-direction: column;
      align-items: flex-start;
      padding: 0.833em 0 0.833em 10px;
    }

    .job-row:hover {
      background-color: #f8f8f8;
    }

    .job-row:last-child {
      border: none;
    }
  }
}
